import React, { useEffect } from "react";
import TopSlider from "../../../components/carousel/TopSlider";
import { Link } from "react-router-dom";
import DashboardBoardJobItem from "../../../components/DashboardBoardJobItem";
import { useDispatch, useSelector } from "react-redux";
import { fetchJobs } from "../../../store/slice/jobSlice";
import DashboardBoardJobItemShimmer from "../../../components/jobs/DashboardBoardJobItemShimmer";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { allJobs, status: jobStatus, error, } = useSelector((state) => state.job);
  // Add page state


  useEffect(() => {
    if (jobStatus === 'idle' || jobStatus === 'failed') {
      dispatch(fetchJobs({ page: 1, limit: 50 }));
    }
  }, [dispatch, jobStatus]);
  return (
    <div className="bg-white p-10 w-full space-y-10">
      <TopSlider />

      <div className="space-y-3">
        <div className=" bg-[#FCF4FE] rounded-lg px-10 py-3  flex justify-between items-center">
          <div className="flex gap-x-3">
            <img src="../up.svg" alt="" />
            <div>
              <h1 className="text-base font-semibold">
                Connect your upwork account
              </h1>
              <p className="text-gray-500 font-light text-sm">
                Apply to jobs faster by connecting your upwork accounts.
              </p>
            </div>
          </div>
          <button>
            <img src="../right.svg" alt="" />
          </button>
        </div>
        <Link to={"/dashboard/profile-optimization"} className="bg-[#FCF4FE] rounded-lg px-10 py-3  flex justify-between items-center border-t pt-3 mt-4">
          <div className="flex gap-x-5">
            <img src="../pro.svg" className="w-12" alt="" />
            <div>
              <h1 className="text-base font-semibold">Profile Optimization</h1>
              <p className="text-gray-500 font-light text-sm">
                Optimize your profile to get more tailored job alerts.
              </p>
            </div>
          </div>
          <button>
            <img src="../right.svg" alt="" />
          </button>
        </Link>
      </div>
      {
        jobStatus === "loading" ? (
          <DashboardBoardJobItemShimmer />
        ) : jobStatus === "failed" ? (
          <p className="text-red-500">Failed to load jobs. Please try again.</p>
        ) : (
          Array.isArray(allJobs) && allJobs.length > 0 ? (
            <div>
              <div className="flex justify-between">
                <h1 className="text-lg font-semibold pb-2">Featured Jobs</h1>
                <Link
                  to="/dashboard/jobs"
                  className="bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text text-transparent flex gap-x-1 items-center"
                >
                  See All <span className="text-xl">{">"}</span>
                </Link>
              </div>

              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                {allJobs.slice(0, 2).map((e, index) => (
                  <DashboardBoardJobItem key={index} jobDetails={e} />
                ))}
              </div>
            </div>
          ) : (
            <div className="text-gray-500 text-center">No jobs available to display.</div>
          )
        )
      }

      {/* Quick Links +++++++++++++++++++++++++ */}
      <div>
        <h1 className="text-lg font-semibold pb-2">Quick Links</h1>
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-4">
          <Link
            to=""
            className="p-3 h-[10rem] rounded-xl bg-cover"
            style={{
              backgroundImage: "url('../banner/quick1.svg')",
            }}
          >
            <h1 className="text-[#20BF6F] text-xl font-semibold w-2/3">
              Real time Job Alert
            </h1>
          </Link>

          <a
            href="https://chromewebstore.google.com/detail/gigshark-extension/nkpfaehkpmmlajcebgpjfpeondcjckog"
            target="_blank"
            className="p-3 h-[10rem] rounded-xl bg-cover"
            style={{
              backgroundImage: "url('../banner/quick2.svg')",
            }}
          >
            <h1 className="text-[#FF4401] text-xl font-semibold w-2/3">
              Web <br /> Extension
            </h1>
          </a>

          <Link
            to=""
            className="p-3 h-[10rem] rounded-xl bg-cover"
            style={{
              backgroundImage: "url('../banner/quick3.svg')",
            }}
          >
            <h1 className="bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text text-transparent text-xl font-semibold w-2/3">
              AI Proposal Suggestions
            </h1>
          </Link>

          <Link
            to=""
            className="p-3 h-[10rem] rounded-xl bg-cover"
            style={{
              backgroundImage: "url('../banner/quick4.svg')",
            }}
          >
            <h1 className="text-[#5A56E1] text-xl font-semibold w-2/3">
              Whatsapp Integration
            </h1>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
