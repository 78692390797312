import React from "react";

function DashboardBoardJobItemShimmer() {
  return (
    <div className="bg-white p-5 border w-full rounded-md space-y-3 animate-pulse">
      {/* Top section */}
      <div className="flex items-center justify-between">
        <div className="flex gap-x-2 items-center">
          <div className="p-2 bg-gray-200 rounded-md w-8 h-8"></div>
          <div className="w-24 h-6 bg-gray-200 rounded"></div>
        </div>
        <div className="w-8 h-8 bg-gray-200 rounded"></div>
      </div>

      {/* Middle section */}
      <div className="flex gap-2 items-center">
        <div className="p-2 bg-gray-200 rounded-md w-16 h-6"></div>
        <div className="p-2 bg-gray-200 rounded-md w-16 h-6"></div>
      </div>

      {/* Description */}
      <div className="mt-5 h-16 bg-gray-200 rounded"></div>

      {/* Bottom section */}
      <div className="flex items-center justify-between border-t pt-2">
        <div className="flex gap-x-4">
          <div className="flex gap-x-2 items-center">
            <div className="w-4 h-4 bg-gray-200 rounded-full"></div>
            <div className="w-16 h-4 bg-gray-200 rounded"></div>
          </div>
          <div className="flex gap-x-2 items-center">
            <div className="w-4 h-4 bg-gray-200 rounded-full"></div>
            <div className="w-16 h-4 bg-gray-200 rounded"></div>
          </div>
        </div>
        <div className="w-16 h-6 bg-gray-200 rounded"></div>
      </div>
    </div>
  );
}

export default DashboardBoardJobItemShimmer;
