// src/components/common/SummaryItem.js

import React from 'react';

const SummaryItem = ({ label, amount, className }) => {
    return (
        <div className={`flex justify-between ${className}`}>
            <span className="text-gray-700">{label}</span>
            <span className="text-gray-800">{amount}</span>
        </div>
    );
};

export default SummaryItem;
