import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axiosInstance';
import endpoints from '../../helpers/apiEndpoints';
import { safeJSONParse } from '../../utils/safeJSONParse';

// Async thunk to fetch all subscriptions except the trial
export const fetchJobs = createAsyncThunk(
    'jobs/fetchJobs',
    async ({ page = 1, limit = 10 }, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.get(
                `${endpoints.getAllJobs}?page=${page}&limit=${limit}`
            );
            return {
                data: response.data.data, // Jobs data
                totalPages: response.data.totalPages, // Assuming the response contains totalPages
            }; // Assuming the response has a 'subscriptions' field
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const fetchJobById = createAsyncThunk(
    'jobs/fetchJobById',
    async (id, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.get(`${endpoints.getJobById}/${id}`);
            return response.data.data; // Assuming response contains the job data in 'data'
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

// Async thunk to search jobs by title
export const searchJobsByTitle = createAsyncThunk(
    'jobs/searchJobsByTitle',
    async (title, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.get(`${endpoints.searchJobs}?title=${title}`);
            return response.data.data; // Assuming response contains the job data in 'data'
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);


const initialState = {
    allJobs: safeJSONParse(localStorage.getItem('allJobs')) || [],
    selectedJob: null, // For storing the fetched job by ID
    searchResults: [], // For storing search results
    currentPage: 1, // For tracking the current page
    totalPages: 1, // For storing total pages
    status: 'idle',
    error: null,
};

const jobSlice = createSlice({
    name: 'job',
    initialState,
    reducers: {
        setAllJobs: (state, action) => {
            state.allJobs = action.payload;
        },
        setPagination: (state, action) => {
            state.currentPage = action.payload.page;
            state.totalPages = action.payload.totalPages;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchJobs.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchJobs.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.allJobs = action.payload.data;
                state.totalPages = action.payload.totalPages;
                localStorage.setItem('allJobs', JSON.stringify(action.payload.data));
                state.error = null;
            })
            .addCase(fetchJobs.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            }).addCase(fetchJobById.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchJobById.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.selectedJob = action.payload; // Store the fetched job in a specific field
                state.error = null;
            })
            .addCase(fetchJobById.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })

            // Search jobs by title
            .addCase(searchJobsByTitle.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(searchJobsByTitle.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.searchResults = action.payload; // Store the search results in a specific field
                state.error = null;
            })
            .addCase(searchJobsByTitle.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    },
});

export const {
    setAllJobs,setPagination
} = jobSlice.actions;
export default jobSlice.reducer;
