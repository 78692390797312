
/**
 * Safely parses a JSON string.
 * @param {string} value - The JSON string to parse.
 * @returns {Object|null} - The parsed object or null if parsing fails.
 */
export function safeJSONParse(value) {
    try {
      return value ? JSON.parse(value) : null;
    } catch (error) {
      console.error('Failed to parse JSON:', error);
      return null;
    }
  }