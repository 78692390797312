import React, { useState } from "react";
import Spinner from "./preLoaders/Spinner";

export default function UpworkButton() {
  const [isLoading, setisLoading] = useState(false);

  return (
    <button className="w-full text-sm  transition-colors duration-300 p-px  rounded-md  bg-gradient-to-r  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  font-semibold  hover:bg-[#F5F5F5] mx-auto ">
      <div className="bg-white w-full p-3 rounded-md">
        {isLoading ? (
          <Spinner />
        ) : (
          <span className="flex items-center justify-center bg-gradient-to-b  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text text-transparent gap-x-2">
            <img src="../up.svg"  alt="" />
            Log In with Upwork
          </span>
        )}
      </div>
    </button>
  );
}
