import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axiosInstance';
import endpoints from '../../helpers/apiEndpoints';
import { setCurrentSubscription, setHasUsedTrial, clearSubscriptionData } from './subscriptionSlice';
export const login = createAsyncThunk('auth/login', async ({ email, password }, { rejectWithValue, dispatch }) => {
  try {
    const response = await axiosInstance.post(`${endpoints.login}`, { email, password });
    const token = response.headers['authorization'];
    localStorage.setItem('authToken', token);  // Save token to localStorage for later use
    localStorage.setItem('userDetails', response.data.data);  // Save user to localStorage for later use
    const user = response.data.data;
    const hasUsedTrial = user.trial_used;

    dispatch(setHasUsedTrial(hasUsedTrial));
    const subscriptionDetails = response.data.subscriptionDetails;
    // Dispatch actions to update subscription state
    if (subscriptionDetails) {
      dispatch(setCurrentSubscription(subscriptionDetails));
    }
    return { user: user, token };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const signup = createAsyncThunk('auth/signup', async (userData, { rejectWithValue, dispatch }) => {
  try {
    const response = await axiosInstance.post(`${endpoints.register}`, userData);
    const token = response.headers['authorization'];
    localStorage.setItem('authToken', token);
    localStorage.setItem('userDetails', response.data.data);  // Save user to localStorage for later use
    const user = response.data.data;
    const hasUsedTrial = user.trial_used;
    dispatch(setHasUsedTrial(hasUsedTrial));
    const subscriptionDetails = response.data.subscriptionDetails;
    // Dispatch actions to update subscription state
    if (subscriptionDetails) {
      dispatch(setCurrentSubscription(subscriptionDetails));
    }
    return { user: user, token };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const performLogout = () => (dispatch) => {
  dispatch(logout());
  dispatch(clearSubscriptionData());
};

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: localStorage.getItem('userDetails') || null,
    token: localStorage.getItem('authToken') || null,
    status: 'idle',
    error: null,
    isLoading: false, // Add isLoading property
  },
  reducers: {
    logout: (state, action) => {
      state.user = null;
      state.token = null;
      localStorage.removeItem('authToken');
      localStorage.removeItem('userDetails');
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status = 'loading';
        state.isLoading = true; // Set isLoading to true
      })
      .addCase(login.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user = action.payload.user;
        state.token = action.payload.token;
        state.error = null; // Set error to null on success
        state.isLoading = false; // Set isLoading to false
      })
      .addCase(login.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
        state.isLoading = false; // Set isLoading to false
      })
      .addCase(signup.pending, (state) => {
        state.status = 'loading';
        state.isLoading = true; // Set isLoading to true
      })
      .addCase(signup.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user = action.payload.user;
        state.token = action.payload.token;
        state.error = null; // Set error to null on success
        state.isLoading = false; // Set isLoading to false
      })
      .addCase(signup.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
        state.isLoading = false; // Set isLoading to false
      });
  },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
