import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Navigation } from "swiper/modules";

function CompanySlide() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  return (
    <Swiper
      slidesPerView={2.5}
      centeredSlides={true}
      spaceBetween={20}
      loop={true}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      modules={[Autoplay, Navigation]}
      breakpoints={{
        640: {
          slidesPerView: 3.2,
          spaceBetween: 12,
        },
        768: {
          slidesPerView: 4.2,
          spaceBetween: 12,
        },
        1024: {
          slidesPerView: 4.2,
          spaceBetween: 12,
        },
      }}
      className="mySwiper items-center"
    >
      <SwiperSlide>
        <img
          className="h-full lg:-my-3 w-auto mx-auto"
          src="./logo1.png"
          alt=""
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          className="h-7 lg:h-11 w-auto mt-4 lg:mt-12 mx-auto"
          src="./logo2.png"
          alt=""
        />
      </SwiperSlide>
      <SwiperSlide>
        <img className="h-full w-auto lg:-my-3 mx-auto" src="./logo3.png" alt="" />
      </SwiperSlide>
      <SwiperSlide>
        <img className="h-full w-auto mx-auto" src="./logo4.png" alt="" />
      </SwiperSlide>
      <SwiperSlide>
        <img className="h-full w-auto mx-auto -my-3 lg:my-0" src="./logo5.png" alt="" />
      </SwiperSlide>
    </Swiper>
  );
}

export default CompanySlide;
