import React from "react";
import Sidebar from "../../components/dashboard/sidebar";
import TopBar from "../../components/dashboard/topbar";
import { Outlet } from "react-router-dom";

const DashboardLayout = () => {
    return (
        <div className="flex h-screen bg-gray-100">
            <Sidebar />
            <div className="flex flex-col flex-1 ml-2 mr-5">
                <TopBar />
                <div className=" py-3 flex-1 bg-gray-100 overflow-auto scrollbar-hide">
                    <Outlet />
                </div>
            </div>
        </div>
    );
};

export default DashboardLayout;