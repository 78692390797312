import React, { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import Login from "./modals/Login";

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  return (
    <div className="z-[100] sticky top-0">
      <header className="bg-white  ">
        <motion.nav
          initial={{ x: -300, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="mx-auto hidden lg:flex  items-center justify-between px-20 "
          aria-label="Global"
        >
          <Link to="/" className=" m-5">
            <img className="h-12 w-auto" src="../logo.png" alt="" />
          </Link>

          <div className="flex gap-5 items-center">
            <a
              href="#"
              className="text-base text-[#808080] hover:text-transparent bg-gradient-to-r  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text focus:text-transparent transition-colors duration-300  px-3 py-2 rounded-sm bg-transparent hover:bg-[#F5F5F5] focus:bg-[#F5F5F5] font-light hover:font-semibold focus:font-semibold uppercase"
            >
              Home
            </a>
            <a
              href="#features"
              className="text-base text-[#808080] hover:text-transparent bg-gradient-to-r  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text focus:text-transparent transition-colors duration-300  px-3 py-2 rounded-sm bg-transparent hover:bg-[#F5F5F5] focus:bg-[#F5F5F5] font-light hover:font-semibold focus:font-semibold uppercase"
            >
              FEATURES
            </a>
            <a
              href="#pricing"
              className="text-base text-[#808080] hover:text-transparent bg-gradient-to-r  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text focus:text-transparent transition-colors duration-300  px-3 py-2 rounded-sm bg-transparent hover:bg-[#F5F5F5] focus:bg-[#F5F5F5] font-light hover:font-semibold focus:font-semibold uppercase"
            >
              PRICING
            </a>

            <a
              href="#about"
              className="text-base text-[#808080] hover:text-transparent bg-gradient-to-r  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text focus:text-transparent transition-colors duration-300  px-3 py-2 rounded-sm bg-transparent hover:bg-[#F5F5F5] focus:bg-[#F5F5F5] font-light hover:font-semibold focus:font-semibold uppercase"
            >
              ABOUT
            </a>
            <a
              href="#blog"
              className="text-base text-[#808080] hover:text-transparent bg-gradient-to-r  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text focus:text-transparent transition-colors duration-300  px-3 py-2 rounded-sm bg-transparent hover:bg-[#F5F5F5] focus:bg-[#F5F5F5] font-light hover:font-semibold focus:font-semibold uppercase"
            >
              BLOG
            </a>
          </div>
          <div className="flex gap-2 items-center">
            <button
              onClick={() => setModal(true)}
              className="w-[6rem]  text-sm  transition-colors duration-300 p-px  rounded-md  bg-gradient-to-r  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  font-bold uppercase hover:bg-[#F5F5F5] mx-auto "
            >
              <div className="bg-white w-full p-3 rounded-md">
                <span className="bg-gradient-to-r  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text text-transparent">
                  LOGIN
                </span>
              </div>
            </button>
            <button
              onClick={() => navigate("/signup")}
              className="w-[11rem]  text-sm custom-gradient transition-colors duration-300  p-3 rounded-md hover:text-[#800080] text-[#FFF] font-bold uppercase hover:bg-[#F5F5F5] mx-auto "
            >
              CREATE ACCOUNT
            </button>
            <div className="relative inline-block group">
              <button
                type="button"
                className="flex items-center gap-x-1  text-sm uppercase bg-[#F5F5F5] border border-[#808080] rounded-lg font-bold  text-[#000] px-2.5 py-2"
              >
                <img src="./flag.svg" alt="" />
                <img src="./down.svg" alt="" />
              </button>
              {/* <!-- Dropdown menu --> */}
              <div className="absolute z-[100] w-20 p-2 hidden group-hover:block origin-top-right bg-white rounded-md shadow-xl ">
                <button className="w-full block px-4 py-2 text-sm text-gray-800  transition-colors duration-300 transform  hover:bg-gray-100  uppercase  font-bold rounded">
                  <img src="./flag.svg" alt="" />
                </button>
              </div>
            </div>
          </div>
        </motion.nav>

        <div className="lg:hidden item-center  flex justify-between px-3 py-5 bg-[#fff] ">
          <Link to="/">
            <span className="sr-only">GigShark</span>
            <img className="h-10 w-auto" src="../logo.png" alt="" />
          </Link>

          <div className="flex lg:hidden">
            {isOpen ? (
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setIsOpen(false);
                }}
                className="text-[#576B74]  hover:text-gray-600  focus:outline-none focus:text-gray-600 "
                aria-label="toggle menu"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6 text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="#576B74"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            ) : (
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setIsOpen(true);
                }}
                className="text-[#576B74]  hover:text-gray-600  focus:outline-none focus:text-gray-600 "
                aria-label="toggle menu"
              >
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 4.5C2 4.23478 2.10536 3.98043 2.29289 3.79289C2.48043 3.60536 2.73478 3.5 3 3.5H21C21.2652 3.5 21.5196 3.60536 21.7071 3.79289C21.8946 3.98043 22 4.23478 22 4.5C22 4.76522 21.8946 5.01957 21.7071 5.20711C21.5196 5.39464 21.2652 5.5 21 5.5H3C2.73478 5.5 2.48043 5.39464 2.29289 5.20711C2.10536 5.01957 2 4.76522 2 4.5ZM3 13.5H21C21.2652 13.5 21.5196 13.3946 21.7071 13.2071C21.8946 13.0196 22 12.7652 22 12.5C22 12.2348 21.8946 11.9804 21.7071 11.7929C21.5196 11.6054 21.2652 11.5 21 11.5H3C2.73478 11.5 2.48043 11.6054 2.29289 11.7929C2.10536 11.9804 2 12.2348 2 12.5C2 12.7652 2.10536 13.0196 2.29289 13.2071C2.48043 13.3946 2.73478 13.5 3 13.5ZM3 21.5H21C21.2652 21.5 21.5196 21.3946 21.7071 21.2071C21.8946 21.0196 22 20.7652 22 20.5C22 20.2348 21.8946 19.9804 21.7071 19.7929C21.5196 19.6054 21.2652 19.5 21 19.5H3C2.73478 19.5 2.48043 19.6054 2.29289 19.7929C2.10536 19.9804 2 20.2348 2 20.5C2 20.7652 2.10536 21.0196 2.29289 21.2071C2.48043 21.3946 2.73478 21.5 3 21.5Z"
                    fill="#1A1A1A"
                  />
                </svg>
              </button>
            )}
          </div>
        </div>

        <div
          className={`${
            isOpen
              ? "translate-x-0 opacity-100 "
              : "opacity-0 -translate-x-full"
          } absolute inset-x-0 z-20 w-full px-6 py-4 transition-all duration-300 ease-in-out bg-[#fff] space-y-3`}
        >
          <div className="flex flex-col px-2 -mx-4 space-y-2">
            <a
              href="#"
              className="text-base text-[#808080] hover:text-transparent bg-gradient-to-r  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text focus:text-transparent transition-colors duration-300  px-3 py-2 rounded-sm bg-transparent hover:bg-[#F5F5F5] focus:bg-[#F5F5F5] font-light hover:font-semibold focus:font-semibold uppercase"
            >
              Home
            </a>
            <a
              href="#features"
              className="text-base text-[#808080] hover:text-[#800080] focus:text-[#800080] transition-colors duration-300  px-3 py-2 rounded-sm bg-transparent hover:bg-[#F5F5F5] focus:bg-[#F5F5F5] font-light hover:font-semibold focus:font-semibold uppercase active:bg-[#808080]"
            >
              FEATURES
            </a>
            <a
              href="#pricing"
              className="text-base text-[#808080] hover:text-transparent bg-gradient-to-r  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text focus:text-transparent transition-colors duration-300  px-3 py-2 rounded-sm bg-transparent hover:bg-[#F5F5F5] focus:bg-[#F5F5F5] font-light hover:font-semibold focus:font-semibold uppercase"
            >
              PRICING
            </a>

            <a
              href="#about"
              className="text-base text-[#808080] hover:text-transparent bg-gradient-to-r  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text focus:text-transparent transition-colors duration-300  px-3 py-2 rounded-sm bg-transparent hover:bg-[#F5F5F5] focus:bg-[#F5F5F5] font-light hover:font-semibold focus:font-semibold uppercase"
            >
              ABOUT
            </a>
            <a
              href="#blog"
              className="text-base text-[#808080] hover:text-transparent bg-gradient-to-r  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text focus:text-transparent transition-colors duration-300  px-3 py-2 rounded-sm bg-transparent hover:bg-[#F5F5F5] focus:bg-[#F5F5F5] font-light hover:font-semibold focus:font-semibold uppercase"
            >
              BLOG
            </a>
            <button
              onClick={() => setModal(true)}
              className="w-full  text-sm  transition-colors duration-300 p-px  rounded-md  bg-gradient-to-r  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  font-bold uppercase hover:bg-[#F5F5F5] mx-auto "
            >
              <div className="bg-white w-full p-3 rounded-md">
                <span className="bg-gradient-to-r  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text text-transparent">
                  LOGIN
                </span>
              </div>
            </button>
            <button
              onClick={() => navigate("/signup")}
              className="w-full  text-sm custom-gradient transition-colors duration-300  p-3 rounded-md hover:text-[#800080] text-[#FFF] font-bold uppercase hover:bg-[#F5F5F5] mx-auto "
            >
              CREATE ACCOUNT
            </button>
          </div>
        </div>
      </header>

      {/* Modal ********************* */}
      {modal && <Login />}
    </div>
  );
}

export default Header;
