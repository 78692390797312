import React from "react";

function JobItem({ setScreen }) {
  return (
    <button
      onClick={() => setScreen(2)}
      className="bg-white p-5 border w-full rounded-md space-y-3"
    >
      <div className="flex items-center justify-between">
        <div className="flex gap-x-2 items-center">
          <div className="p-2 bg-[#F5F5F5] rounded-md">
            <img src="../logo7.svg" alt="" />
          </div>
          <h2 className="text-sm text-[#1a1a1a] font-[Gilroy-Bold] md:text-xl lg:text-2xl ">
            Mobile App Design for GigShark
          </h2>
        </div>
        <img src="badge.svg" alt="" />
      </div>
      <div className="flex  gap-2 items-center">
        <div className="p-2 bg-[#F5F5F5] rounded-md">
          <h2 className="text-xs text-left  md:text-sm bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9] font-light  bg-clip-text text-transparent">
            Full-time
          </h2>
        </div>
        <div className="p-2 bg-[#F5F5F5] rounded-md">
          <h2 className="text-xs text-left  md:text-sm  bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9] font-light  bg-clip-text text-transparent">
            Senior Role
          </h2>
        </div>
      </div>
      <p className="mt-5 text-left font-light text-[#62747d] text-sm px-6 lg:px-0 sm:w-[40rem] lg:w-[48rem] mx-auto ">
        We are looking for a mobile designer with at least 3 years of experience
        working in an agile environment.
      </p>
      <div className="flex items-center justify-between">
        <div className="flex gap-x-2 items-center">
          <div className="flex gap-x-2 items-center">
            <img src="../loci.svg" alt="" />
            <p className="text-xs  lg:text-sm  text-[#808080] font-extralight">
              Mumbai, India
            </p>
          </div>
          <div className="flex gap-x-2 items-center">
            <img src="../u_clock.svg" alt="" />
            <p className="text-xs  lg:text-sm  text-[#808080] font-extralight">
              8 days ago
            </p>
          </div>
        </div>

        <p className="text-xs  lg:text-base  text-[#808080] font-light flex justify-center items-baseline">
          <span className="font-semibold  bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text text-transparent">
            $500
          </span>
          /mo
        </p>
      </div>
    </button>
  );
}

export default JobItem;
