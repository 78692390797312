// src/components/PaymentPage/BillingForm.js

import React, { useState } from 'react';
import InputField from '../common/InputField';

const BillingForm = () => {
    const [billingInfo, setBillingInfo] = useState({
        name: '',
        email: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        cardName: '',
        cardNumber: '',
        expiryDate: '',
        cvv: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setBillingInfo((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission, validation, and payment processing
        console.log('Billing Information:', billingInfo);
    };

    return (
        <form onSubmit={handleSubmit}>
            <h2 className="text-2xl font-semibold mb-6">Billing Information</h2>
            <div className="grid grid-cols-1 gap-4">
                <InputField
                    label="Full Name"
                    name="name"
                    type="text"
                    value={billingInfo.name}
                    onChange={handleChange}
                    required
                />
                <InputField
                    label="Email Address"
                    name="email"
                    type="email"
                    value={billingInfo.email}
                    onChange={handleChange}
                    required
                />
                <InputField
                    label="Address"
                    name="address"
                    type="text"
                    value={billingInfo.address}
                    onChange={handleChange}
                    required
                />
                <InputField
                    label="City"
                    name="city"
                    type="text"
                    value={billingInfo.city}
                    onChange={handleChange}
                    required
                />
                <InputField
                    label="State/Province"
                    name="state"
                    type="text"
                    value={billingInfo.state}
                    onChange={handleChange}
                    required
                />
                <InputField
                    label="ZIP/Postal Code"
                    name="zip"
                    type="text"
                    value={billingInfo.zip}
                    onChange={handleChange}
                    required
                />
                <InputField
                    label="Country"
                    name="country"
                    type="text"
                    value={billingInfo.country}
                    onChange={handleChange}
                    required
                />
            </div>

            <h2 className="text-2xl font-semibold my-6">Card Details</h2>
            <div className="grid grid-cols-1 gap-4">
                <InputField
                    label="Cardholder Name"
                    name="cardName"
                    type="text"
                    value={billingInfo.cardName}
                    onChange={handleChange}
                    required
                />
                <InputField
                    label="Card Number"
                    name="cardNumber"
                    type="text"
                    value={billingInfo.cardNumber}
                    onChange={handleChange}
                    required
                />
                <div className="flex space-x-4">
                    <InputField
                        label="Expiry Date (MM/YY)"
                        name="expiryDate"
                        type="text"
                        value={billingInfo.expiryDate}
                        onChange={handleChange}
                        required
                    />
                    <InputField
                        label="CVV"
                        name="cvv"
                        type="text"
                        value={billingInfo.cvv}
                        onChange={handleChange}
                        required
                    />
                </div>
            </div>

            {/* <Button type="submit" className="mt-6 w-full">
                Proceed to Payment
            </Button> */}
        </form>
    );
};

export default BillingForm;
