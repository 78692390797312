import React from "react";
import { Link } from "react-router-dom";
import { timeAgo } from "../helpers/timeAgo";

function DashboardBoardJobItem({ jobDetails }) {

  return (
    <Link
      to={`/dashboard/job-details/${jobDetails.id}`}
      className="bg-white p-5 border w-full rounded-md space-y-3 hover:hover-gradient "
    >
      <div className="flex items-center justify-between">
        <div className="flex gap-x-2 items-center">
          <div className="p-2 bg-[#F5F5F5] rounded-md">
            <img src="../up.svg" alt="" />
          </div>
          <h2 className="text-sm text-[#1a1a1a] font-[Gilroy-Bold] md:text-xl lg:text-2xl ">
            {jobDetails.category_id}
          </h2>
        </div>
        <img src="../badge.svg" alt="" />
      </div>
      <div className="flex  gap-2 items-center">
        <div className="p-2 bg-[#F5F5F5] rounded-md">
          <h2 className="text-xs text-left  md:text-sm bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9] font-light  bg-clip-text text-transparent">
            {jobDetails.type}
          </h2>
        </div>
        <div className="p-2 bg-[#F5F5F5] rounded-md">
          <h2 className="text-xs text-left  md:text-sm  bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9] font-light  bg-clip-text text-transparent">
            {jobDetails.level}
          </h2>
        </div>
      </div>
      <p className="mt-5 text-left font-light text-[#62747d] text-sm px-6 lg:px-0 mx-auto ">
        {jobDetails.description}
      </p>
      <div className="flex items-center justify-between border-t pt-2">
        <div className="flex gap-x-2 items-center">
          <div className="flex gap-x-2 items-center">
            <img src="../loci.svg" alt="" />
            <p className="text-xs  lg:text-sm  text-[#808080] font-extralight">
              Remote
            </p>
          </div>
          <div className="flex gap-x-2 items-center">
            <img src="../u_clock.svg" alt="" />
            <p className="text-xs  lg:text-sm  text-[#808080] font-extralight">
              {timeAgo(jobDetails.ts_publish)}
            </p>
          </div>
        </div>

        <p className="text-xs  lg:text-base  text-[#808080] font-light flex justify-center items-baseline">
          <span className="font-semibold  bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text text-transparent">
            ${jobDetails.budget_amount}
          </span>
        </p>
      </div>
    </Link>
  );
}

export default DashboardBoardJobItem;
