import React from "react";

function Error404() {
  return (
    <div className="p-6 h-screen bg-[#FCF4FE] mx-auto align-middle rounded-2xl">
      <div className="mx-auto text-center  pb-5">
        <img
          src="../error1.svg"
          alt=""
          className="mx-auto w-[20rem] lg:w-[27rem] my-5"
        />
        <h2 className="text-lg md:text-4xl font-semibold text-[#000] tracking-[.10em] ">
          Something is not right!
        </h2>
        <p className="text-[12px] sm:text-[18px]  sm:pt-0.5  font-light text-[#808080]  ">
          Hey there! apologies, we’re working on fixing this.
        </p>
        <button className="flex items-center justify-center gap-x-2 w-[15rem] md:w-[18rem] font-bold  text-xs sm:text-sm custom-gradient transition-colors duration-300  px-2 py-3 mt-7 rounded-md hover:text-[#800080] text-[#FFF] font-semibolds uppercase hover:bg-[#c0c5c5] mx-auto ">
          Go back to your Dashboard
        </button>
      </div>
    </div>
  );
}

export default Error404;
