const Pagination = ({ currentPage, totalPages, handlePageChange }) => {
    // Helper function to determine which pages to show
    const getPageNumbers = () => {
      const pageNumbers = [];
      const maxPagesToShow = 11; // Max number of pages to display (first 5 + last 6)
  
      // If there are fewer pages than the max to show, display all of them
      if (totalPages <= maxPagesToShow) {
        for (let i = 1; i <= totalPages; i++) {
          pageNumbers.push(i);
        }
      } else {
        // Show the first 5 pages
        for (let i = 1; i <= 5; i++) {
          pageNumbers.push(i);
        }
  
        // Add ellipsis if there are more pages between the first 5 and the last 6
        if (currentPage < totalPages - 6) {
          pageNumbers.push('...');
        }
  
        // Show the last 6 pages
        for (let i = totalPages - 5; i <= totalPages; i++) {
          pageNumbers.push(i);
        }
      }
  
      return pageNumbers;
    };
  
    const pageNumbers = getPageNumbers();
  
    return (
      <div className="my-6 lg:flex sm:items-center sm:justify-center hidden gap-x-5 px-4">
        <div className="text-sm font-light text-[#808080]">
          Showing
          <span className="font-medium text-gray-700 px-1">1 - 10</span>
          of {totalPages}
        </div>
  
        <div className="flex items-center mt-4 gap-x-5 sm:mt-0">
          {pageNumbers.map((page, idx) =>
            page === '...' ? (
              <span key={idx} className="text-[#414D52] font-bold">
                ...
              </span>
            ) : (
              <button
                key={idx}
                className={`rounded-lg py-2 px-5 text-[#414D52] font-bold ${currentPage === page ? 'bg-[#FCF4FE]' : 'bg-[#F5F5F5]'}`}
                onClick={() => handlePageChange(page)}
              >
                {page}
              </button>
            )
          )}
  
          <button
            className="flex items-center gap-x-2 rounded-lg py-2 px-5 bg-[#fff] hover-gradient border text-[#fff]"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            <span className="bg-gradient-to-br from-[#6E5DE9] via-[#A159F2] to-[#CD55F9] bg-clip-text text-transparent">
              Next Page
            </span>
            <img src="/left-sm.svg" alt="" />
          </button>
        </div>
      </div>
    );
  };
  export default Pagination;