import React, { useState } from "react";
import Select from "react-select";

const MultiSelect = ({ label, options }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  // Handle selection changes
  const handleChange = (selected) => {
    setSelectedOptions(selected || []);
  };

  const getPlaceholderText = () => {
    return selectedOptions.length > 0
      ? `${selectedOptions.length} ${label.toLowerCase()} selected`
      : `Select ${label}...`;
  };

  return (
    <div>
      {/* Dynamic Label */}
      <label className="text-left block text-gray-800 font-semibold py-2">
        {label}:
      </label>
      <Select
        isMulti
        options={options}
        value={selectedOptions}
        onChange={handleChange}
        className="basic-multi-select"
        classNamePrefix="select"
        placeholder={getPlaceholderText()} // Dynamic placeholder
      />

      {/* Display selected options */}
      <div className="flex flex-wrap gap-3 mt-1 border p-2 rounded">
        {selectedOptions.map((option, index) => (
          <div
            key={index}
            className="flex gap-x-2 items-center px-3 py-1 text-sm rounded-full bg-[#F5F5F5] capitalize"
          >
            {option.label}
            <button
              type="button"
              onClick={() =>
                setSelectedOptions(
                  selectedOptions.filter((item) => item.value !== option.value)
                )
              }
              className=" text-red-500 font-light bg-white px-1.5 rounded-full"
            >
              x
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MultiSelect;
