import React from "react";

function Preloader() {
  return (

    <div className="bg-[#fff]/50 flex justify-center h-screen">
      <div className="flex flex-col justify-center">
        <svg
          width="168"
          height="168"
          viewBox="0 0 168 168"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className=" animate-pulse"
        >
          <path
            d="M92.3327 0.666626H75.666V50.6666H92.3327V0.666626ZM92.3327 117.333H75.666V167.333H92.3327V117.333ZM167.333 75.6666V92.3333H117.333V75.6666H167.333ZM50.666 92.3333V75.6666H0.666016V92.3333H50.666ZM108.999 42.3333H125.666V59H108.999V42.3333ZM142.333 25.6666H125.666V42.3333H142.333V25.6666ZM58.9994 42.3333H42.3327V59H58.9994V42.3333ZM25.666 25.6666H42.3327V42.3333H25.666V25.6666ZM108.999 125.667H125.666V142.333H142.333V125.667H125.666V109H108.999V125.667ZM42.3327 125.667V109H58.9994V125.667H42.3327ZM42.3327 125.667V142.333H25.666V125.667H42.3327Z"
            fill="url(#paint0_linear_306_5425)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_306_5425"
              x1="150.348"
              y1="28.7983"
              x2="48.4367"
              y2="-33.3037"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#CD55F9" />
              <stop offset="0.486667" stop-color="#A159F2" />
              <stop offset="1" stop-color="#6E5DE9" />
            </linearGradient>
          </defs>
        </svg>
        Loading... Please wait
      </div>
    </div>
  );
}

export default Preloader;
