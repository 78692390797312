import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import MainLayout from "./components/layouts/MainLayout";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import TermsAndConditions from "./pages/Privacy";
import GuestRoute from "./Routes/GuestRoute";
import ProtectedRoute from "./Routes/ProtectedRoute";
import DashboardLayout from "./pages/Protected/Dashboard";
import { JobsPage } from "./pages/Protected/pages/jobs";
import { NotificationPage } from "./pages/Protected/pages/notifications";
import Plans from "./pages/Protected/pages/plans";
import Upgrade from "./components/modals/Upgrade";
import { useDispatch, useSelector } from "react-redux";
import { fetchCurrentSubscription } from "./store/slice/subscriptionSlice";
import { SubscriptionPlans } from "./pages/Protected/pages/subscriptions";
import PaymentPage from "./pages/Protected/pages/payment";
import { SettingsPage } from "./pages/Protected/pages/settings";
import Dashboard from "./pages/Protected/pages/dashboard";
import JobDetails from "./components/jobs/JobDetails";
import Profile from "./pages/Protected/pages/profile";
import ErrorPage from "./pages/ErrorPage";
import Error404 from "./pages/Error404";


function App() {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { currentSubscription, status: subscriptionStatus } = useSelector(
    (state) => state.subscription
  );

  useEffect(() => {
    if (token && subscriptionStatus === "idle") {
      dispatch(fetchCurrentSubscription());
    }
  }, [token, subscriptionStatus, dispatch]);

  return (
    <div className="font-[Poppins]">
      {/* <TawkMessengerReact
        propertyId="653384a2f2439e1631e6acd6"
        widgetId="1hd8js7fu"
      /> */}
      <Router>
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route path="/" element={<Home />} />

            <Route path="/privacy" element={<TermsAndConditions />} />
            <Route path="/support" element={<TermsAndConditions />} />
          </Route>

          <Route path="error" element={<ErrorPage />} />
          <Route path="404" element={<Error404 />} />
          <Route
            path="login"
            element={
              <GuestRoute>
                <Login />
              </GuestRoute>
            }
          />
          <Route
            path="signup"
            element={
              <GuestRoute>
                <Signup />
              </GuestRoute>
            }
          />
          <Route path="upgrade" element={<Upgrade />} />
          <Route path="plans" element={<Plans />} />
          <Route
            path="payment"
            element={
              <ProtectedRoute>
                <PaymentPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <DashboardLayout />
              </ProtectedRoute>
            }
          >
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/dashboard/jobs" element={<JobsPage />} />
            <Route path="/dashboard/job-details/:id" element={<JobDetails />} />
            <Route path="/dashboard/job-details/:id/generate" element={<JobDetails />} />
            <Route
              path="/dashboard/profile-optimization"
              element={<Profile />}
            />
            <Route path="/dashboard/settings" element={<SettingsPage />} />
            <Route
              path="/dashboard/notifications"
              element={<NotificationPage />}
            />
            <Route
              path="/dashboard/subscription"
              element={<SubscriptionPlans />}
            />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
