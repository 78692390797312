import { useState } from 'react';
import { ChangePassword } from '../../../components/settings/changePassword';
import Notification from '../../../components/settings/notification';
import ConnectAccount from '../../../components/settings/connectAccount';
import DeleteAccount from '../../../components/settings/deleteAccount';

export const SettingsPage = () => {
    const [activeTab, setActiveTab] = useState('Password');

    const tabs = [
        'Password',
        'Notifications',
        'Connected Accounts',
        'Delete Account'
    ];

    return (
        <div className="jobs-page bg-white h-full p-5">
            {/* Tab Menu */}
            <div className="flex border-b border-[#FCF4FE] mb-5 w-full bg-[#F0E7FF] p-2 rounded-md items-center">
                {tabs.map((tab) => (
                    <div
                        key={tab}
                        className={`flex items-center p-2 cursor-pointer ${activeTab === tab ? 'bg-white rounded-md m-1' : 'text-grey2'}`}
                        onClick={() => setActiveTab(tab)}
                    >
                        {activeTab === tab ? (
                            <div className="px-4 py-2">
                                <span
                                    className="text-transparent bg-clip-text"
                                    style={{
                                        backgroundImage: 'linear-gradient(301.36deg, #CD55F9 37.81%, #A159F2 63.16%, #6E5DE9 89.91%)',
                                        WebkitTextFillColor: 'transparent',
                                        WebkitBackgroundClip: 'text'
                                    }}
                                >
                                    {tab}
                                </span>
                            </div>
                        ) : (
                            <span>{tab}</span>
                        )}
                    </div>
                ))}
            </div>

            {/* Tab Content */}
            <div className="tab-content rounded-md">
                {activeTab === 'Password' && <ChangePassword />}
                {activeTab === 'Notifications' && <Notification />}
                {activeTab === 'Connected Accounts' && <ConnectAccount />}
                {activeTab === 'Delete Account' && <DeleteAccount />}
            </div>
        </div>
    );
};
