import React from "react";
import { useNavigate } from "react-router-dom";

function Login() {
  const navigate = useNavigate();
  return (
    <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
      <div className="flex items-end justify-end sm:min-h-screen px-4 pt-4 pb-20 text-right sm:block sm:p-0">
        <span
          className="hidden sm:inline-block sm:h-screen sm:align-middle"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div className="relative inline-block px-4 pt-5 pb-4 overflow-hidden text-center align-top transition-all transform bg-white  shadow-xl  top-20 md:top-0   w-full sm:max-w-xl sm:p-6 md:p-16 ">
          <div className="flex flex-col items-center justify-center align-middle min-h-[45rem] space-y-7">
            <img className="h-20 w-auto" src="../logo.png" alt="" />
            <h2 className="text-xl text-[#1a1a1a] font-medium md:text-4xl lg:text-4xl font-[Gilroy-Bold] lg:tracking-[.10em] text-center lg:text-left">
              Login to GigShark
            </h2>

            <button
              onClick={() => navigate("/login")}
              className="w-full flex justify-center  text-sm  transition-colors duration-300 p-3 text-white bg-gradient-to-r  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  font-semibold capitalize hover:bg-[#F5F5F5]"
            >
              Log In Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
