import React, { useState } from "react";

function Notification() {
  const [enabled, setEnabled] = useState(false);
  return (
    <div className="lg:w-[50rem] space-y-4">
      <div className="flex justify-between border p-4 rounded-lg bg-[#FEFCFF]">
        <div>
          <h2 className="text-sm text-left text-[#1a1a1a] font-[Gilroy-Bold] md:text-lg">
            Whatsapp Notification
          </h2>
          <p className="text-xs  lg:text-sm  text-[#808080] font-extralight">
            This option allows you to receive or cancel notifications on your
            connected whatsapp account.
          </p>
        </div>

        <div className="flex items-center">
          <label className="inline-flex relative items-center mr-5 cursor-pointer">
            <input
              type="checkbox"
              className="sr-only peer"
              checked={enabled}
              readOnly
            />
            <div
              onClick={() => {
                setEnabled(!enabled);
              }}
              className="w-11 h-6 bg-gray-200 rounded-full peer  peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#76EE59]"
            ></div>
          </label>
        </div>
      </div>

      <div className="flex justify-between border p-4 rounded-lg bg-[#FEFCFF]">
        <div>
          <h2 className="text-sm text-left text-[#1a1a1a] font-[Gilroy-Bold] md:text-lg">
            Push Notifcation
          </h2>
          <p className="text-xs  lg:text-sm  text-[#808080] font-extralight">
            This option allows you receive or cancel push notifications.
          </p>
        </div>

        <div className="flex items-center">
          <label className="inline-flex relative items-center mr-5 cursor-pointer">
            <input
              type="checkbox"
              className="sr-only peer"
              checked={enabled}
              readOnly
            />
            <div
              onClick={() => {
                setEnabled(!enabled);
              }}
              className="w-11 h-6 bg-gray-200 rounded-full peer  peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#76EE59]"
            ></div>
          </label>
        </div>
      </div>
    </div>
  );
}

export default Notification;
