import React from "react";
import { useLocation ,Link} from "react-router-dom";
import { ReactComponent as NotificationIcon } from '../../icons/Notification.svg';
import { ReactComponent as CaretDownIcon } from '../../icons/caret-down.svg';
import { useSelector } from "react-redux";
import { useState } from "react";


const TopBar = () => {
    const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);

    const location = useLocation();
    const paths = location.pathname.split('/');
    let pageTitle = paths.length > 2 ? paths[2] : 'Dashboard'; // Get the section after '/dashboard'

    // Capitalize the first letter
    pageTitle = pageTitle.charAt(0).toUpperCase() + pageTitle.slice(1);

    const user = useSelector((state) => state.auth.user); // Get user from Redux state
    const toggleProfileMenu = () => {
        setIsProfileMenuOpen(!isProfileMenuOpen);
    };
    return (
        <div className="w-full h-16 bg-white shadow-md flex justify-between items-center px-4">
            <div className="text-xl font-semibold">{pageTitle}</div>
            <div className="flex items-center space-x-4">
                <NotificationIcon className="w-6 h-6 text-gray-600" />
                <div className="relative flex items-center" onClick={toggleProfileMenu}>
                    {user ? (
                        <div className="w-10 h-10 bg-purple-600 text-white rounded-full flex items-center justify-center font-bold">
                            {user.first_name.charAt(0).toUpperCase()}{user.last_name.charAt(0).toUpperCase()}
                        </div>
                    ) : (
                        <img src="/path/to/avatar.jpg" alt="Profile" className="w-10 h-10 rounded-full" />
                    )}
                    <CaretDownIcon className="w-4 h-4 text-gray-600 ml-2" />

                    {isProfileMenuOpen && (
                        <div className="absolute top-12 right-0 bg-white shadow-lg border rounded-md p-4 w-64 z-[9999]">
                            <div className="flex items-center mb-4">
                                <div className="w-10 h-10 bg-purple-600 text-white rounded-full flex items-center justify-center font-bold">
                                    {user ? (
                                        `${user.first_name.charAt(0).toUpperCase()}${user.last_name.charAt(0).toUpperCase()}`
                                    ) : (
                                        <img src="/path/to/avatar.jpg" alt="Profile" className="w-10 h-10 rounded-full" />
                                    )}
                                </div>
                                <div className="ml-3">
                                    <div className="font-semibold">{user ? `${user.first_name} ${user.last_name}` : 'Guest'}</div>
                                    <div className="text-sm text-gray-500">{user ? user.email : 'guest@example.com'}</div>
                                </div>
                            </div>
                            <hr className="my-2" />
                            <ul className="text-sm">
                                <li className="py-1 hover:bg-gray-100 cursor-pointer">
                                    <Link to="/dashboard/view-profile" className="text-black" onClick={() => setIsProfileMenuOpen(false)}>View Profile</Link>
                                </li>
                                <li className="py-1 hover:bg-gray-100 cursor-pointer">
                                    <Link to="/dashboard/profile-optimization" className="text-black" onClick={() => setIsProfileMenuOpen(false)}>Profile Optimization</Link>
                                </li>
                                <li className="py-1 hover:bg-gray-100 cursor-pointer">
                                    <Link to="/dashboard/subscription" className="text-black" onClick={() => setIsProfileMenuOpen(false)}>Subscription</Link>
                                </li>
                                <li className="py-1 hover:bg-gray-100 cursor-pointer">
                                    <Link to="/dashboard/help" className="text-black" onClick={() => setIsProfileMenuOpen(false)}>Help</Link>
                                </li>                            
                            </ul>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
export default TopBar;