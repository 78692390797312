import React, { useState } from "react";
import JobItem from "../JobItem";

function Account() {
  const [screen, setScreen] = useState(1);
  return (
    <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
      <div className="flex items-center justify-center sm:min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <span
          className="hidden sm:inline-block sm:h-screen sm:align-middle"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div className="relative inline-block px-4 pt-5 pb-4 overflow-hidden text-center align-top md:align-middle transition-all transform bg-white  shadow-xl  top-20 md:top-0 sm:my-8  w-full sm:max-w-3xl md:max-w-4xl sm:p-8 md:p-16 ">
          <div className="flex items-center justify-between">
            <img className="h-12 w-auto" src="../logo.png" alt="" />
            <img src="../avatar.svg" className="h-9 w-auto" alt="" />
          </div>
          <div className="flex flex-col items-center justify-center space-y-5 mt-5">
            <h2 className="text-xl text-[#1a1a1a] font-[Gilroy-Bold]  text-center md:text-3xl lg:text-5xl mx-auto lg:tracking-[.10em]">
              Hi, John Doe
            </h2>
            <h2 className="text-xl text-[#808080] font-[Gilroy-Bold]  text-center md:text-3xl lg:text-5xl mx-auto lg:tracking-[.10em]">
              How can I help you today?
            </h2>

            {/* Screen 1 Job Listings */}
            <div className={screen === 1 ? "w-full" : "hidden"}>
              <h2 className="text-sm text-left  md:text-2xl  bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9] font-medium  bg-clip-text text-transparent">
                Available Jobs For You
              </h2>

              <div className="flex flex-col items-center justify-center space-y-5 mt-5">
                <JobItem setScreen={setScreen} />
                <JobItem setScreen={setScreen} />
                <JobItem setScreen={setScreen} />
              </div>
            </div>

            {/* Screen 2 Job Details */}
            <div className={screen === 2 ? "w-full" : "hidden"}>
              <div className="flex flex-col items-center justify-center space-y-5 mt-5">
                <div className="bg-white p-5 border w-full rounded-md space-y-3">
                  <div className="flex items-center justify-between">
                    <div className="flex gap-x-2 items-center">
                      <div className="p-2 bg-[#F5F5F5] rounded-md">
                        <img src="../logo7.svg" alt="" />
                      </div>
                      <h2 className="text-sm text-[#1a1a1a] font-[Gilroy-Bold] md:text-xl ">
                        Mobile App Design for GigShark
                      </h2>
                    </div>
                    <img src="badge.svg" alt="" />
                  </div>
                  <div className="flex  gap-2 items-center">
                    <div className="p-2 bg-[#F5F5F5] rounded-md">
                      <h2 className="text-xs text-left  md:text-sm bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9] font-light  bg-clip-text text-transparent">
                        Full-time
                      </h2>
                    </div>
                    <div className="p-2 bg-[#F5F5F5] rounded-md">
                      <h2 className="text-xs text-left  md:text-sm  bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9] font-light  bg-clip-text text-transparent">
                        Senior Role
                      </h2>
                    </div>
                  </div>
                  <p className="mt-5 text-left font-light text-[#62747d] text-sm px-6 lg:px-0 sm:w-[40rem] lg:w-[48rem] mx-auto ">
                    We are looking for a mobile designer with at least 3 years
                    of experience working in an agile environment.
                  </p>
                  <div className="flex items-center justify-between">
                    <div className="flex gap-x-2 items-center">
                      <div className="flex gap-x-2 items-center">
                        <img src="../loci.svg" alt="" />
                        <p className="text-xs  lg:text-sm  text-[#808080] font-extralight">
                          Mumbai, India
                        </p>
                      </div>
                      <div className="flex gap-x-2 items-center">
                        <img src="../u_clock.svg" alt="" />
                        <p className="text-xs  lg:text-sm  text-[#808080] font-extralight">
                          8 days ago
                        </p>
                      </div>
                    </div>

                    <p className="text-xs  lg:text-base  text-[#808080] font-light flex justify-center items-baseline">
                      <span className="font-semibold  bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text text-transparent">
                        $500
                      </span>
                      /mo
                    </p>
                  </div>
                </div>

                <details className="group w-full">
                  <summary className="w-full flex justify-between items-center font-medium cursor-pointer bg-[#FCF4FE] list-none  p-3">
                    <span className="font-semibold  bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text text-transparent">
                      View Job Details
                    </span>
                    <span className="transition group-open:rotate-180">
                      <svg
                        fill="none"
                        height="24"
                        shape-rendering="geometricPrecision"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                        viewBox="0 0 24 24"
                        width="24"
                      >
                        <path d="M6 9l6 6 6-6"></path>
                      </svg>
                    </span>
                  </summary>
                  <div className="border p-4 group-open:animate-fadeIn">
                    <div className="flex  items-center justify-between">
                      <div>
                        <p className="text-xs text-left text-[#808080] font-extralight">
                          Location
                        </p>
                        <p className="text-xs  lg:text-sm  text-[#1a1a1a] font-light">
                          Mumbai, India
                        </p>
                        <p className="text-xs mt-2 lg:text-sm  text-[#808080] font-light flex justify-start items-baseline">
                          <span className="font-semibold  bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text text-transparent">
                            $500
                          </span>
                          /mo
                        </p>
                      </div>

                      <div>
                        <p className="text-xs text-[#808080] font-extralight text-right">
                          Applicants
                        </p>
                        <p className="text-xs  lg:text-sm  text-[#1a1a1a] font-light text-right">
                          100+
                        </p>
                        <div className="flex mt-2 gap-2 items-center">
                          <div className="p-2 bg-[#FCF4FE] rounded-md">
                            <h2 className="text-xs text-left  bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9] font-normal  bg-clip-text text-transparent">
                              Full-time
                            </h2>
                          </div>
                          <div className="p-2 bg-[#FCF4FE] rounded-md">
                            <h2 className="text-xs text-left    bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9] font-normal  bg-clip-text text-transparent">
                              Senior Role
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>

                    <h2 className="text-sm text-left text-[#1a1a1a] font-[Gilroy-Bold] md:text-lg">
                      Description
                    </h2>
                    <ul className="list-disc text-xs text-left text-[#808080] font-light px-4 space-y-2">
                      <li>
                        We are looking for a mobile designer with at least 3
                        years of experience working in an agile environment.
                      </li>
                      <li>
                        We are looking for a mobile designer with at least 3
                        years of experience working in an agile environment.
                      </li>
                      <li>
                        We are looking for a mobile designer with at least 3
                        years of experience working in an agile environment.
                      </li>
                      <li>
                        We are looking for a mobile designer with at least 3
                        years of experience working in an agile environment.
                      </li>
                    </ul>

                    <h2 className="text-sm text-left mt-2 text-[#1a1a1a] font-[Gilroy-Bold] md:text-lg">
                      Responsibilities
                    </h2>
                    <ul className="list-disc text-xs text-left text-[#808080] font-light px-4 space-y-2">
                      <li>
                        We are looking for a mobile designer with at least 3
                        years of experience working in an agile environment.
                      </li>
                      <li>
                        We are looking for a mobile designer with at least 3
                        years of experience working in an agile environment.
                      </li>
                    </ul>
                  </div>
                </details>

                <div className="w-full">
                  <h2 className="text-sm text-left text-[#1a1a1a] font-[Gilroy-Bold] md:text-xl">
                    Additional Info
                  </h2>
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="10"
                    placeholder="Enter anything else to back up your application and make it stand out"
                    className="bg-[#F5F5F5] p-3 border rounded-lg w-full placeholder:font-light"
                  ></textarea>
                </div>

                <button
                  onClick={() => setScreen(3)}
                  className="w-full text-center text-xs custom-gradient transition-colors duration-300  p-4 rounded hover:text-[#fff] text-[#FFF]  hover:bg-[#c0c5c5] mx-auto "
                >
                  Generate Proposal
                </button>
              </div>
            </div>

            {/* Screen 2 Job Details */}
            <div className={screen === 3 ? "w-full" : "hidden"}>
              <div className="bg-[#F5F5F5] rounded-xl p-5 space-y-3 border border-[#808080]">
                <p className="text-left font-light text-[#62747d] text-sm px-6 lg:px-0  mx-auto ">
                  Lorem ipsum dolor sit amet consectetur. Id a purus a massa
                  enim purus nunc risus. Aliquet at porttitor a sit. Cras
                  fermentum egestas tristique duis neque a faucibus. Eu nulla eu
                  orci arcu blandit. Nulla amet sit lorem sed pellentesque.
                </p>
                <p className="text-left font-light text-[#62747d] text-sm px-6 lg:px-0  mx-auto ">
                  Luctus aliquet id fames feugiat lorem duis ac sed sit. Vel
                  lobortis vestibulum gravida morbi amet. Duis sed commodo id
                  posuere ultrices. Risus suspendisse nibh lorem nec tincidunt
                  ullamcorper. In nec urna semper sit turpis consectetur eget
                  egestas in. Blandit turpis pretium sit vel amet nec et. Ac
                  sollicitudin fermentum sit sagittis sodales praesent lobortis.
                  Luctus amet aliquet ac feugiat.
                </p>
              </div>

              <div className="flex mt-5 items-center justify-between w-full">
                <div className="flex items-center gap-x-3">
                  <img src="../mcon1.svg" alt="" />
                  <img src="../mcon2.svg" alt="" />
                  <img src="../mcon3.svg" alt="" />
                </div>
                <button
                  onClick={() => setScreen(1)}
                  className="w-[10rem] text-center text-xs custom-gradient transition-colors duration-300  p-4 rounded-lg hover:text-[#fff] text-[#FFF]  hover:bg-[#c0c5c5] "
                >
                  Submit Proposal
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Account;
