import React, { useEffect, useState } from "react";
import SEO from "../components/SEO";
import AppleButton from "../components/AppleButton";
import GoogleButton from "../components/GoogleButton copy";
import UpworkButton from "../components/UpworkButton";
import { useNavigate } from "react-router-dom";
import { signup } from "../store/slice/authSlice";
import { useDispatch, useSelector } from "react-redux";

function Signup() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticated, error, isLoading } = useSelector((state) => state.auth);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const [formData, setFormData] = useState({
    email: '',
    password: '',
    countryId: 0,
    mobileNumber: '',
    firstName: '',
    lastName: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const [screen, setScreen] = useState(1);
  const [passwordType, setPasswordType] = useState("password");


  const handleSignup = (e) => {
    e.preventDefault();
    dispatch(signup(formData));
  };

  return (
    <div className="bg-[#FCF4FE] flex justify-center align-middle py-24 min-h-screen">
      <SEO
        title="Sign Up"
        description="Empowers Service Providers on Top Freelancing Platforms"
        name="GigShark"
        type="description"
      />

      {/* Sign In Modal  */}
      <div className="flex flex-col items-center justify-center px-4 pt-5 pb-4 text-center align-middle transition-all transform bg-white rounded-2xl   top-20 md:top-0   w-full sm:max-w-2xl sm:p-6 md:p-10 sm:align-middle ">
        <div className="w mx-auto">
          <img className="h-20 w-auto" src="../logo.png" alt="" />
          <div className="text-center pt-3">
            <span className="text-sm text-[#1a1a1a] font-light">
              Already have an account?
            </span>
            <button
              onClick={(e) => {
                e.preventDefault();
                navigate("/login");
              }}
              className="text-sm font-semibold bg-gradient-to-r  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text text-transparent px-1"
            >
              Log In
            </button>
          </div>
        </div>

        <div className={screen === 1 ? "flex flex-col mt-7 w-full" : "hidden"}>
          <button
            onClick={(e) => {
              e.preventDefault();
              setScreen(2);
            }}
            className="w-full bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9] rounded-lg p-3 text-white font-semibold transition duration-200 hover:bg-[#757E7E]"
          >
            <span className="flex items-center justify-center gap-x-1">
              Sign up with Email
            </span>
          </button>
        </div>

        {/* Sign In with Email ******************************* */}
        <form onSubmit={handleSignup} className={screen === 2 ? "w-full my-4" : "hidden"}>
          <div className="flex flex-row justify-between">
            <div className="flex-1 mb-3  w-[45%] mr-3">
              <label className="relative bg-white w-20 pb-1  rounded-full px-auto  block text-xs md:text-sm text-[#1a1a1a] font-light">
                First Name
              </label>
              <input
                type="name"
                required
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                placeholder="Enter first name"
                className="py-3 px-5 border-[0.5px] bg-[#F5F5F5] rounded-lg w-full focus:outline-none text-xs placeholder:font-light "
              />
            </div>
            <div className="flex-1 mb-3  w-[45%] ">
              <label className="relative bg-white w-20 pb-1  rounded-full px-auto  block text-xs md:text-sm text-[#1a1a1a] font-light">
                Last Name
              </label>
              <input
                type="name"
                required
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                placeholder="Enter last name"
                className="py-3 px-5 border-[0.5px] bg-[#F5F5F5] rounded-lg w-full focus:outline-none text-xs placeholder:font-light "
              />
            </div>
          </div>

          <div className="mb-3">
            <label className="relative bg-white w-28 pb-1  rounded-full px-auto  block text-xs md:text-sm text-[#1a1a1a] font-light">
              Email Address
            </label>
            <input
              type="email"
              required
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Enter email address"
              className="py-3 px-5 border-[0.5px] bg-[#F5F5F5] rounded-lg w-full focus:outline-none text-xs placeholder:font-light"
            />
          </div>

          <div className="mb-3">
            <label className="relative bg-white w-28 pb-1  rounded-full px-auto  block text-xs md:text-sm text-[#1a1a1a] font-light">
              Phone Number
            </label>
            <input
              type="phone"
              required
              name="mobileNumber"
              value={formData.mobileNumber}
              onChange={handleChange}
              placeholder="Enter phone number"
              className="py-3 px-5 border-[0.5px] bg-[#F5F5F5] rounded-lg w-full focus:outline-none text-xs placeholder:font-light"
            />
          </div>

          <div className="mb-5">
            <label className="relative bg-white w-16 pb-1  rounded-full px-auto  block text-xs md:text-sm text-[#1a1a1a] font-light">
              Password
            </label>
            <div className="flex -mr-px">
              <input
                required
                type={passwordType}
                name="password"
                value={formData.password}
                onChange={handleChange}
                className="py-2.5 px-5 border-[0.5px] bg-[#F5F5F5] rounded-lg w-full focus:outline-none"
              />
              <span className="relative -ml-12 sm:-ml-16 flex items-center px-3 leading-normal text-gray-600 whitespace-no-wrap border-0 rounded rounded-l-none">
                <button
                  type="button"
                  onClick={() => {
                    if (passwordType === "password") {
                      setPasswordType("text");
                      return;
                    }
                    setPasswordType("password");
                  }}
                  className="flex justify-center w-4 sm:w-full py-1 text-base text-gray-500 transition duration-200 ease-in-out rounded cursor-pointer hover:scale-110 focus:outline-none"
                >
                  {passwordType === "password" ? (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 6.5C13.8387 6.49389 15.6419 7.00678 17.2021 7.97973C18.7624 8.95267 20.0164 10.3462 20.82 12C19.17 15.37 15.8 17.5 12 17.5C8.2 17.5 4.83 15.37 3.18 12C3.98362 10.3462 5.23763 8.95267 6.79788 7.97973C8.35813 7.00678 10.1613 6.49389 12 6.5ZM12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 9.5C12.663 9.5 13.2989 9.76339 13.7678 10.2322C14.2366 10.7011 14.5 11.337 14.5 12C14.5 12.663 14.2366 13.2989 13.7678 13.7678C13.2989 14.2366 12.663 14.5 12 14.5C11.337 14.5 10.7011 14.2366 10.2322 13.7678C9.76339 13.2989 9.5 12.663 9.5 12C9.5 11.337 9.76339 10.7011 10.2322 10.2322C10.7011 9.76339 11.337 9.5 12 9.5ZM12 7.5C9.52 7.5 7.5 9.52 7.5 12C7.5 14.48 9.52 16.5 12 16.5C14.48 16.5 16.5 14.48 16.5 12C16.5 9.52 14.48 7.5 12 7.5Z"
                        fill="#808080"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.2556 2.74408C17.5811 3.06951 17.5811 3.59715 17.2556 3.92259L3.9223 17.2559C3.59687 17.5814 3.06923 17.5814 2.74379 17.2559C2.41836 16.9305 2.41836 16.4028 2.74379 16.0774L16.0771 2.74408C16.4026 2.41864 16.9302 2.41864 17.2556 2.74408Z"
                        fill="#808080"
                      />
                      <path
                        d="M13.166 4.4767C12.2274 4.03469 11.1691 3.75 9.9997 3.75C7.54629 3.75 5.58202 5.00308 4.18604 6.33307C2.78685 7.66611 1.87897 9.14973 1.51958 9.7915C1.28406 10.2121 1.25601 10.7143 1.44934 11.1603C1.58435 11.4718 1.81395 11.9544 2.15237 12.5143C2.39041 12.9082 2.9027 13.0346 3.29659 12.7965C3.69049 12.5585 3.81683 12.0462 3.57879 11.6523C3.3066 11.2019 3.11772 10.8124 3.00316 10.5537C3.34129 9.95778 4.14208 8.67693 5.33569 7.53975C6.56858 6.36513 8.14141 5.41667 9.9997 5.41667C10.6681 5.41667 11.2995 5.53937 11.8915 5.75116L13.166 4.4767Z"
                        fill="#808080"
                      />
                      <path
                        d="M14.7418 7.61491C15.8923 8.73288 16.6655 9.97087 16.9962 10.5537C16.8817 10.8124 16.6928 11.2019 16.4206 11.6523C16.1826 12.0462 16.3089 12.5585 16.7028 12.7965C17.0967 13.0346 17.609 12.9082 17.847 12.5143C18.1855 11.9544 18.4151 11.4718 18.5501 11.1603C18.7434 10.7143 18.7153 10.2121 18.4798 9.7915C18.1297 9.16625 17.2589 7.74193 15.9204 6.43629L14.7418 7.61491Z"
                        fill="#808080"
                      />
                      <path
                        d="M9.99971 6.66667C10.3026 6.66667 10.5979 6.69898 10.8824 6.76034L9.1675 8.47519C8.45626 8.7262 7.89258 9.28987 7.64157 10.0011L5.92672 11.716C5.86536 11.4315 5.83305 11.1362 5.83305 10.8333C5.83305 8.53215 7.69853 6.66667 9.99971 6.66667Z"
                        fill="#808080"
                      />
                      <path
                        d="M9.99971 13.3333C9.70732 13.3333 9.42665 13.2831 9.16585 13.1909L7.91498 14.4418C8.52815 14.7968 9.2402 15 9.99971 15C12.3009 15 14.1664 13.1345 14.1664 10.8333C14.1664 10.0738 13.9632 9.36177 13.6081 8.74859L12.3573 9.99947C12.4495 10.2603 12.4997 10.5409 12.4997 10.8333C12.4997 12.214 11.3804 13.3333 9.99971 13.3333Z"
                        fill="#808080"
                      />
                    </svg>
                  )}
                </button>
              </span>
            </div>
          </div>
          {error?.message && <p className="text-red-500 text-sm mt-2 mb-3">{error.message}</p>}
          <div className="flex flex-col space-y-5 w-full">
            <button
              type="submit"
              value="submit"
              disabled={isLoading}
              className="w-full bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9] rounded-lg p-3 text-white font-semibold transition duration-200 hover:bg-[#757E7E]"
            >
              <span className="flex items-center justify-center gap-x-1">
                {isLoading ? 'Signing Up...' : 'Sign Up'}
              </span>
            </button>
          </div>
        </form>
        <div className="flex items-center justify-center my-7 border-t-slate-300 w-full relative">
          <hr className="h-0.5 w-full bg-[#808080]" />
          <div className="mt-1 font-light text-[#808080] bg-white px-5 absolute">
            Or
          </div>
          <hr />
        </div>
        <div className="flex flex-col w-full space-y-5 mt-5">
          <GoogleButton />
          <AppleButton />
          <UpworkButton />
        </div>
      </div>
    </div>
  );
}

export default Signup;
