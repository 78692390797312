// src/utils/axiosInstance.js
import axios from 'axios';
import endpoints from '../helpers/apiEndpoints';
import { performLogout } from '../store/slice/authSlice';

let storeInstance; // Placeholder for the Redux store

export const configureAxios = (store) => {
  storeInstance = store; // Dynamically set the store instance
};
const axiosInstance = axios.create({
  baseURL: endpoints.baseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Request interceptor to add auth token to headers
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('authToken');
    if (token) {
      config.headers.Authorization = `${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor for error handling
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      // Check for unauthorized status
      if (error.response.status === 401) {
        console.error("Unauthorized access - redirecting to login");
        storeInstance.dispatch(performLogout()); // Dispatch the performLogout action
        window.location.href = '/login'; // Adjust the path to your login route
      }
      // Log any other errors
      console.error(error.response.data.message || "An error occurred");
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
