import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Link, useNavigate } from "react-router-dom";
import "./SwiperStyles.css";

function TopSlider() {
  const navigate = useNavigate();

  return (
    <div className="w-full overflow-hidden max-w-5xl"> {/* Ensure Swiper respects parent width */}
      <Swiper
        slidesPerView={1.2}
        centeredSlides={true}
        spaceBetween={12}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, Navigation, Pagination]} // Add Pagination module
        pagination={{
          clickable: true, // Makes pagination dots clickable
        }}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 12,
          },
          768: {
            slidesPerView: 2.2,
            spaceBetween: 12,
          },
          1024: {
            slidesPerView: 2.5,
            spaceBetween: 12,
          },
        }}
        className="mySwiper mt-5 "
      >
        <SwiperSlide>
          <div
            className="bg-cover p-5 rounded-xl "
            style={{
              "background-image": "url('../banner/slide1.svg')",
            }}
          >
            <div className="w-2/3">
              <h1 className="text-lg font-semibold pb-2">Premium Plan</h1>
              <p className="text-gray-500 font-light text-sm">
                Unlock more cool features by subscribing to our premium plan. This
                allows you to do more as a Freelancer.
              </p>
              <div className="flex justify-start items-center gap-x-2 mt-3">
                <Link to="/dashboard/subscription" className=" bg-gradient-to-br from-[#6E5DE9] via-[#A159F2] to-[#CD55F9] rounded-lg py-2 px-3 text-white font-extralight transition duration-200 hover:bg-[#757E7E]">
                  ✨ Upgrade
                </Link>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className="bg-cover p-5 rounded-xl "
            style={{
              "background-image": "url('../banner/slide2.svg')",
            }}
          >
            <div className="w-2/3">
              <h1 className="text-lg font-semibold pb-2">Refer Friends</h1>
              <p className="text-gray-500 font-light text-sm">
                Invite friends to use GigShark and stand the chance to use
                GigShark Premium free for 1 month
              </p>
              <div className="flex justify-start items-center gap-x-2 mt-8">
                <button className=" bg-gradient-to-br from-[#6E5DE9] via-[#A159F2] to-[#CD55F9] rounded-lg py-2 px-3 text-white font-extralight transition duration-200 hover:bg-[#757E7E]">
                  ✨ Get Started
                </button>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className="bg-cover p-5 rounded-xl "
            style={{
              "background-image": "url('../banner/slide1.svg')",
            }}
          >
            <div className="w-2/3">
              <h1 className="text-lg font-semibold pb-2">Premium Plan</h1>
              <p className="text-gray-500 font-light text-sm">
                Unlock more cool features by subscribing to our premium plan. This
                allows you to do more as a Freelancer.
              </p>
              <div className="flex justify-start items-center gap-x-2 mt-3">
                <Link to="/dashboard/subscription" className=" bg-gradient-to-br from-[#6E5DE9] via-[#A159F2] to-[#CD55F9] rounded-lg py-2 px-3 text-white font-extralight transition duration-200 hover:bg-[#757E7E]">
                  ✨ Upgrade
                </Link>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className="bg-cover p-5 rounded-xl "
            style={{
              "background-image": "url('../banner/slide1.svg')",
            }}
          >
            <div className="w-2/3">
              <h1 className="text-lg font-semibold pb-2">Premium Plan</h1>
              <p className="text-gray-500 font-light text-sm">
                Unlock more cool features by subscribing to our premium plan. This
                allows you to do more as a Freelancer.
              </p>
              <div className="flex justify-start items-center gap-x-2 mt-3">
                <Link to="/dashboard/subscription" className=" bg-gradient-to-br from-[#6E5DE9] via-[#A159F2] to-[#CD55F9] rounded-lg py-2 px-3 text-white font-extralight transition duration-200 hover:bg-[#757E7E]">
                  ✨ Upgrade
                </Link>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className="bg-cover p-5 rounded-xl "
            style={{
              "background-image": "url('../banner/slide1.svg')",
            }}
          >
            <div className="w-2/3">
              <h1 className="text-lg font-semibold pb-2">Premium Plan</h1>
              <p className="text-gray-500 font-light text-sm">
                Unlock more cool features by subscribing to our premium plan. This
                allows you to do more as a Freelancer.
              </p>
              <div className="flex justify-start items-center gap-x-2 mt-3">
                <Link to="/dashboard/subscription" className=" bg-gradient-to-br from-[#6E5DE9] via-[#A159F2] to-[#CD55F9] rounded-lg py-2 px-3 text-white font-extralight transition duration-200 hover:bg-[#757E7E]">
                  ✨ Upgrade
                </Link>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className="bg-cover p-5 rounded-xl "
            style={{
              "background-image": "url('../banner/slide1.svg')",
            }}
          >
            <div className="w-2/3">
              <h1 className="text-lg font-semibold pb-2">Premium Plan</h1>
              <p className="text-gray-500 font-light text-sm">
                Unlock more cool features by subscribing to our premium plan. This
                allows you to do more as a Freelancer.
              </p>
              <div className="flex justify-start items-center gap-x-2 mt-3">
                <Link to="/dashboard/subscription" className=" bg-gradient-to-br from-[#6E5DE9] via-[#A159F2] to-[#CD55F9] rounded-lg py-2 px-3 text-white font-extralight transition duration-200 hover:bg-[#757E7E]">
                  ✨ Upgrade
                </Link>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}

export default TopSlider;