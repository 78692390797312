import React from "react";

function Upgrade() {
  return (
    <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
      <div className="flex items-center justify-center sm:min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <span
          className="hidden sm:inline-block sm:h-screen sm:align-middle"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div className="relative inline-block px-4 pt-5 pb-4 overflow-hidden text-center align-top md:align-middle transition-all transform bg-white  shadow-2xl  top-20 md:top-0 sm:my-8  w-full sm:max-w-3xl md:max-w-4xl sm:p-8 md:p-16 ">
          <div className="flex flex-col items-center justify-center space-y-5 mt-5">
            <img className="h-14 w-auto" src="../logo.png" alt="" />
            <h2 className="text-xl text-[#1a1a1a] font-[Gilroy-Bold]  text-center md:text-3xl lg:text-5xl mx-auto lg:tracking-[.12em]">
              Upgrade to Unlimited Annual Plan to{" "}
              <span className="bg-gradient-to-r  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text text-transparent">
                Unlock Advanced Models
              </span>
            </h2>
          </div>

          <div className=" w-full   bg-[#fff] p-8  rounded-md shadow">
            <div className="w-full space-y-2  py-4">
              <h1 className="flex items-center justify-between text-xs sm:text-base lg:text-xl font-medium bg-gradient-to-r  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text text-transparent w-full">
                PREMIUM PLAN
                <button className=" font-semibold  text-sm bg-[#20BF6F] transition-colors duration-300  p-2  rounded-md hover:text-[#800080] text-[#FFF] font-semibolds  hover:bg-[#c0c5c5] ">
                  33% OFF
                </button>
              </h1>{" "}
              <p className="text-xs sm:text-base lg:text-xl  text-[#000] font-[Gilroy-Bold] flex justify-start items-baseline gap-x-1">
                <span className="text-3xl lg:text-5xl"> $340</span>
                /month
              </p>
              <p className="text-xs sm:text-base lg:text-xl  text-[#808080] font-[Gilroy-Bold] flex justify-start items-baseline gap-x-1 line-through ">
                <span className=""> $340</span>
                /month
              </p>
              <p className="font-light text-[#808080] text-left text-lg">
                $100 Annual plan billing after 3-day trial
              </p>
            </div>
            <div className="space-y-2 my-7">
              <div className="flex items-center gap-x-2">
                <img src="./check.svg" alt="" />
                <p className="font-light text-[#808080] text-center text-lg">
                  $0 payment today
                </p>
              </div>
              <div className="flex items-center gap-x-2">
                <img src="./check.svg" alt="" />
                <p className="font-light text-[#808080] text-center text-lg">
                  Cancel Plan Anytime
                </p>
              </div>
            </div>
            <div className="space-y-5">
              <button className="flex items-center justify-center gap-x-2 w-full font-semibold  text-sm custom-gradient transition-colors duration-300  px-2 py-4 rounded-md hover:text-[#800080] text-[#FFF] font-semibolds  hover:bg-[#c0c5c5] mx-auto ">
                Start 3-day Free Trial
              </button>
              <button>
                <span className="bg-gradient-to-r  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text text-transparent font-semibold">
                  View all plans and featuers
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Upgrade;
