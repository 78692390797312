import React from "react";
import { GradientButton } from "../../../components/common/button";
import MultiSelect from "../../../components/common/multiSelect";


export default function Profile() {

  const languageOptions = [
    { value: "english", label: "English" },
    { value: "spanish", label: "Spanish" },
    { value: "french", label: "French" },
    { value: "german", label: "German" },
  ];

  const skillOptions = [
    { value: "javascript", label: "JavaScript" },
    { value: "react", label: "React" },
    { value: "python", label: "Python" },
    { value: "css", label: "CSS" },
  ];
  return (
    <div className="pr-10">
      <div className="bg-white">
        <div className="lg:w-[40rem] p-10 space-y-5">
          <div className="w-full">
            <h2 className="text-sm text-left text-[#1a1a1a] font-[Gilroy-Bold] ">
              About
            </h2>
            <textarea
              rows={5}
              placeholder="Enter password"
              className="bg-[#F5F5F5] p-3 border rounded-lg w-full placeholder:font-light"
            />
            <div className="flex justify-between py-1">
              <span className="text-xs font-light">0/1200</span>
              <span className="text-xs font-light bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text text-transparent">
                AI Rewriter
              </span>
            </div>
          </div>

          <MultiSelect label="Skills" options={skillOptions} />
          <MultiSelect label="Languages" options={languageOptions} />

          <div className="w-full">
            <h2 className="text-sm text-left text-[#1a1a1a] font-light">
              LinkedIn Profile Link
            </h2>
            <div className="relative w-full">
              <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                <img src="../linkedin.svg" alt="" />
              </div>
              <input
                type="text"
                id="simple-search"
                className="bg-[#F5F5F5] text-[#808080] placeholder:font-light text-sm rounded-lg   block w-full pl-10 p-3 "
                placeholder="https://www."
                required
              />
            </div>
          </div>
          <div className="w-full">
            <h2 className="text-sm text-left text-[#1a1a1a] font-light">
              Youtube Profile Link
            </h2>
            <div className="relative w-full">
              <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                <img src="../utube.svg" alt="" />
              </div>
              <input
                type="text"
                id="simple-search"
                className="bg-[#F5F5F5] text-[#808080] placeholder:font-light text-sm rounded-lg   block w-full pl-10 p-3 "
                placeholder="https://www."
                required
              />
            </div>
          </div>
          <div className="w-full">
            <h2 className="text-sm text-left text-[#1a1a1a] font-light">
              X (Twitter) Profile Link
            </h2>
            <div className="relative w-full">
              <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                <img src="../x.svg" alt="" />
              </div>
              <input
                type="text"
                id="simple-search"
                className="bg-[#F5F5F5] text-[#808080] placeholder:font-light text-sm rounded-lg   block w-full pl-10 p-3 "
                placeholder="https://www."
                required
              />
            </div>
          </div>

          <div className="flex w-[12rem]">
            <GradientButton>Update</GradientButton>
          </div>
        </div>
      </div>
    </div>
  );
}
