// const baseUrl = 'http://localhost:4000/api/';
const baseUrl = 'https://api.gigshark.io/api/';

const endpoints = {
    baseUrl: baseUrl,
    login: `${baseUrl}public/users/login`,
    register: `${baseUrl}public/users/signup`,
    getUserProfile: `${baseUrl}users/profile`,
    getAllSubscriptions: `${baseUrl}subscription/fetchSubscriptions`,
    startFreeTrial: `${baseUrl}subscription/startTrial`,
    getUserSubscription: `${baseUrl}subscription/getCurrentSubscriptions`,
    changePassword: `${baseUrl}users/changePassword`,
    getAllJobs: `${baseUrl}jobs/fetchJobs`,
    getJobById: `${baseUrl}jobs`,
    searchJobs: `${baseUrl}obs/search`,
};

export default endpoints;