// src/components/common/InputField.js

import React from 'react';

const InputField = ({ label, name, type, value, onChange, required }) => {
    return (
        <div className="flex flex-col">
            <label htmlFor={name} className="mb-1 font-medium text-gray-700">
                {label}
            </label>
            <input
                id={name}
                name={name}
                type={type}
                value={value}
                onChange={onChange}
                required={required}
                className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
            />
        </div>
    );
};

export default InputField;
