import React from "react";

export default function ConnectAccount() {
  return (
    <div className="lg:w-[50rem] space-y-4">
      <div className="flex justify-between border p-4 rounded-lg bg-[#FEFCFF]">
        <div className="flex items-center gap-x-2">
          <img src="../up.svg" className="w-[4rem]" alt="" />
          <div>
            <h2 className="text-sm text-left text-[#1a1a1a] font-[Gilroy-Bold] md:text-base">
              Upwork
            </h2>
            <p className="text-xs  lg:text-sm  text-[#808080] font-extralight">
              https://www.upwork.com
            </p>
          </div>
        </div>

        <button className="font-bold text-xl">
          <span className="bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text text-transparent">
            Disconnect
          </span>
        </button>
      </div>

      <div className="flex justify-between border p-4 rounded-lg bg-[#FEFCFF]">
        <div className="flex items-center gap-x-2">
          <img src="../google.svg" className="w-[3rem]" alt="" />{" "}
          <div>
            <h2 className="text-sm text-left text-[#1a1a1a] font-[Gilroy-Bold] md:text-base">
              Google
            </h2>
            <p className="text-xs  lg:text-sm  text-[#808080] font-extralight">
              https://www.google.com
            </p>
          </div>
        </div>

        <button className="font-bold text-xl">
          <span className="bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text text-transparent">
            Disconnect
          </span>
        </button>
      </div>

      {/* Empty  */}
      <div className="p-6 mt-5 pb-10">
        <div className="mx-auto text-center ">
          <img src="../connected.svg" alt="" className="mx-auto w-[18rem]" />
          <h2 className="text-base md:text-lg font-bold text-[#1A1A1A]  ">
            No Connected Accounts
          </h2>
          <p className="text-[12px] sm:text-[12px]  sm:pt-0.5  font-light text-[#808080]  ">
            When you link other apps to GigShark, they will show up here
          </p>
        </div>
      </div>
    </div>
  );
}
