import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchJobById } from "../../store/slice/jobSlice";
import { Link, useParams } from "react-router-dom";
import PageLoader from "../preLoaders/PageLoader";

function JobDetails() {
  const params = useParams();
  const { id } = params;
  const dispatch = useDispatch();
  const { selectedJob, status, error } = useSelector((state) => state.job);

  useEffect(() => {
    dispatch(fetchJobById(id));
  }, [dispatch, id]);

  if (status === 'loading') return <PageLoader />;
  if (error) return <p>Error: {error.message}</p>;
  if (!selectedJob) return <p>No job details available</p>;  // Handle null case

  return (
    <div>
      <div className="flex flex-col items-center space-y-5 pr-10 mt-5">
        <div className="bg-white p-5 w-full rounded-md space-y-3">
          <div className="flex items-center justify-between lg:pr-10">
            <div className="flex gap-x-2 items-center">
              <div className="p-2 bg-[#F5F5F5] rounded-md">
                <img src="/up.svg" alt="" />
              </div>
              <h2 className="text-sm text-[#1a1a1a] font-[Gilroy-Bold] md:text-xl">
                {selectedJob.title}
              </h2>
            </div>
            <img src="/badge.svg" alt="" />
          </div>

          <div className="flex items-center justify-between lg:pr-10">
            <div className="flex flex-col">
              <p className="text-xs lg:text-sm text-[#808080] font-extralight">
                Location
              </p>
              <div className="flex gap-x-2 items-center">
                <img src="/loci.svg" alt="" />
                <p className="text-xs lg:text-sm text-[#000] font-light">
                  Mumbai, India
                </p>
              </div>
            </div>

            <div>
              <p className="text-xs text-[#808080] font-extralight text-right">
                Applicants
              </p>
              <p className="text-xs lg:text-sm text-[#1a1a1a] font-light text-right">
                100+
              </p>
            </div>
          </div>
          <div className="flex items-center justify-between lg:pr-10">
            <div className="flex gap-2 items-center">
              <div className="p-2 bg-[#FCF4FE] rounded-md">
                <h2 className="text-xs text-left md:text-sm bg-gradient-to-br from-[#6E5DE9] via-[#A159F2] to-[#CD55F9] font-light bg-clip-text text-transparent">
                  {selectedJob.type}
                </h2>
              </div>
              <div className="p-2 bg-[#FCF4FE] rounded-md">
                <h2 className="text-xs text-left md:text-sm bg-gradient-to-br from-[#6E5DE9] via-[#A159F2] to-[#CD55F9] font-light bg-clip-text text-transparent">
                  {selectedJob.level} Role
                </h2>
              </div>
            </div>

            <p className="text-xs lg:text-base text-[#808080] font-light flex justify-center items-baseline">
              <span className="font-semibold bg-gradient-to-br from-[#6E5DE9] via-[#A159F2] to-[#CD55F9] bg-clip-text text-transparent">
                ${selectedJob.budget_amount}
              </span>
            </p>
          </div>
        </div>

        {/* Description +++++++++++++++++++++++++ */}
        <div className="bg-white p-10 w-full rounded-md space-y-3">
          <h2 className="text-sm text-left text-[#1a1a1a] font-[Gilroy-Bold] md:text-lg">
            Description
          </h2>
          <p className="text-xs text-left text-[#808080] font-light px-4 space-y-2">
            {selectedJob.description}
          </p>

          <h2 className="text-sm text-left mt-2 text-[#1a1a1a] font-[Gilroy-Bold] md:text-lg">
            Skills
          </h2>
          <ul className="list-disc text-xs text-left text-[#808080] font-light px-4 space-y-2">
            {selectedJob.skills && selectedJob.skills.map((skill) => (
              <li key={skill.id}>{skill.name}</li>
            ))}
          </ul>
        </div>

        {/* Buttons ++++++++++++++++++++++ */}
        <div className="w-full flex gap-x-4 items-center">
          <a href={selectedJob.url} target="_blank" className="w-full md:w-[12rem] text-center font-semibold text-xs bg-[#FCF4FE] hover-gradient transition-colors duration-300 p-4 rounded hover:bg-[#c0c5c5]/10 ">
            <span className="bg-gradient-to-br from-[#6E5DE9] via-[#A159F2] to-[#CD55F9] bg-clip-text text-transparent">
              Apply on Upwork
            </span>
          </a>
          <Link to="generate" className="w-full md:w-[12rem] text-center text-xs custom-gradient transition-colors duration-300 p-4 rounded hover:text-[#fff] text-[#FFF] hover:bg-[#c0c5c5]">
            AI Proposal Writer
          </Link>
        </div>
      </div>
    </div>
  );
}

export default JobDetails;
