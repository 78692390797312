import React from "react";

export default function DeleteAccount() {
  return (
    <div className="lg:w-[50rem] space-y-4 p-5 border rounded-md">
      <div>
        <h2 className="text-sm text-left text-[#1a1a1a] font-[Gilroy-Bold] md:text-base">
          Account Deletion
        </h2>
        <p className="text-xs  lg:text-sm  text-[#808080] font-extralight">
          Kindly note that you’ll lose your jobs, profile and all your GigShark
          data.
        </p>
      </div>
      <p className="text-xs  lg:text-sm  text-[#808080] font-extralight">
        For your security, enter your password to delete your account. This
        cannot be undone.
      </p>

      <div className="w-full">
        <h2 className="text-sm text-left text-[#1a1a1a] font-[Gilroy-Bold] md:text-base">
          Password
        </h2>
        <input
          placeholder="Enter password"
          className="bg-[#F5F5F5] p-3 border rounded-lg w-full placeholder:font-light"
        />
      </div>

      <button className="bg-[#FAEBEB] rounded-md font-semibold p-3 text-[#FF4401]">
        Permanently Delete Account
      </button>
    </div>
  );
}
