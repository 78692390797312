import { configureStore, combineReducers } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import authReducer from './slice/authSlice';
import subscriptionReducer from './slice/subscriptionSlice';
import jobReducer from './slice/jobSlice';
import { configureAxios } from '../utils/axiosInstance';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
};

const rootReducer = combineReducers({
  auth: authReducer,
  subscription: subscriptionReducer,
  job: jobReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});

// Configure Axios with the store
configureAxios(store);
const persistor = persistStore(store);

export { store, persistor };
