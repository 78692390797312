import React from "react";

export default function LoginSpinner() {
  return (
    <div className="flex items-center gap-x-1">
      Logging In...
      <svg
        width="32"
        height="33"
        viewBox="0 0 32 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="animate-spin"
      >
        <path
          d="M15.9999 5.8335V8.88112M23.619 8.88112L21.3333 11.1668M8.38087 8.88112L10.6666 11.1668M15.9999 27.1668V24.1192M23.619 24.1192L21.3333 21.8335M8.38087 24.1192L10.6666 21.8335M5.33325 16.5002H8.38087M23.619 16.5002H26.6666"
          stroke="black"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
}
