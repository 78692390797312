import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSubscriptions, startFreeTrial } from '../../../store/slice/subscriptionSlice'; // Adjust the path as needed
import Preloader from '../../../components/preLoaders/Preloader';
import { performLogout } from '../../../store/slice/authSlice';
import { useNavigate } from 'react-router-dom';

function Plans() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { allSubscriptions, status, error, hasUserUsedTrial } = useSelector((state) => state.subscription);

    useEffect(() => {
        if (status === 'idle' || status === 'failed') {
            dispatch(fetchSubscriptions());
        }
    }, [dispatch, status]);


    const handleSelectPlan = useCallback(
        (plan) => {
            navigate(`/payment?planId=${plan.id}`);
        },
        [navigate]
    );

    if (status === 'loading') {
        return <Preloader />;
    }

    if (status === 'failed') {
        return <div>Error: {error.message || 'Failed to load subscription plans.'}</div>;
    }

    const handleLogout = () => {
        const customDialog = document.createElement('div');
        customDialog.innerHTML = `
                <div id="logoutDialog" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[9999]">
                    <div class="bg-white rounded-lg p-6 text-center w-80">
                        <h2 class="text-xl font-semibold mb-4 text-purple-600">Confirm Logout</h2>
                        <p class="mb-4">Are you sure you want to log out?</p>
                        <div class="flex justify-center space-x-4">
                            <button id="confirmLogout" class="px-4 py-2 bg-purple-600 text-white rounded hover:bg-purple-700">Yes</button>
                            <button id="cancelLogout" class="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400">Cancel</button>
                        </div>
                    </div>
                </div>
            `;
        document.body.appendChild(customDialog);

        document.getElementById('confirmLogout').onclick = () => {
            document.body.removeChild(customDialog);
            dispatch(performLogout());
        };
        document.getElementById('cancelLogout').onclick = () => {
            document.body.removeChild(customDialog);
        };
    };

    const handleStartFreeTrial = () => {
        dispatch(startFreeTrial())
            .unwrap()
            .then(() => {
                console.log('Free trial started successfully');
                // Optionally navigate or update UI
            })
            .catch((error) => {
                console.error('Error starting free trial:', error);
                alert(error.message || 'An error occurred while starting the free trial.');
            });
    };

    return (
        <div
            id="pricing"
            className="min-h-screen bg-[#F3F4FE] py-16 px-5 lg:px-24 flex flex-col items-center justify-center gap-x-1"        >
            {/* Header Section */}
            <div className="w-full flex items-center justify-between px-4 lg:px-8 mb-8">
                {/* Go Back Home Button */}
                <button
                    onClick={() => navigate('/')}
                    className="flex items-center gap-x-2 text-purple-600 hover:text-purple-800 font-semibold text-sm"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                    </svg>
                    Go back Home
                </button>

                {/* Logout Button */}
                <button
                    onClick={handleLogout}
                    className="text-purple-600 hover:text-purple-800 font-semibold text-sm"
                >
                    Logout
                </button>
            </div>
            <div className="container mx-auto py-10">
                {/* ... Header and description ... */}

                <div className="grid grid-cols-1 gap-8 md:gap-3 lg:gap-8 md:grid-cols-3 lg:grid-cols-3">
                    {Array.isArray(allSubscriptions) && allSubscriptions.map((plan) => (
                        <div
                            key={plan.id}
                            className={`relative flex flex-col items-center justify-center bg-white pt-5 pb-10 rounded-md shadow ${plan.name === 'Standard' ? 'border-2 border-purple-500' : ''
                                }`}
                        >
                            {/* Recommended Badge */}
                            {plan.name === 'Standard' && (
                                <div className="absolute top-0 right-0 mt-2 mr-2">
                                    <span className="bg-purple-500 text-white text-xs font-bold px-2 py-1 rounded">
                                        Recommended
                                    </span>
                                </div>
                            )}
                            <div className="w-full text-center space-y-2 border-b px-8 py-4">
                                <h1 className="text-xs sm:text-base lg:text-xl font-semibold bg-gradient-to-br from-[#6E5DE9] via-[#A159F2] to-[#CD55F9] bg-clip-text text-transparent">
                                    {plan.name.toUpperCase()} PLAN
                                </h1>
                                <p className="text-xs sm:text-base lg:text-xl text-[#000] font-[Gilroy-Bold] flex justify-center items-baseline gap-x-1">
                                    <span className="text-5xl lg:text-6xl">
                                        ${parseFloat(plan.price).toFixed(2)}
                                    </span>
                                    /month
                                </p>
                            </div>
                            <div className="space-y-2 my-7">
                                {/* Max Profiles */}
                                <div className="flex items-center gap-x-2">
                                    <img src="./check.svg" alt="" />
                                    <p className="font-light text-[#808080] text-center text-lg">
                                        {plan.max_profiles == null
                                            ? 'Unlimited Profiles'
                                            : `${plan.max_profiles} Profile(s)`}
                                    </p>
                                </div>

                                {/* Max Proposals */}
                                <div className="flex items-center gap-x-2">
                                    <img src="./check.svg" alt="" />
                                    <p className="font-light text-[#808080] text-center text-lg">
                                        {plan.max_proposals == null
                                            ? 'Unlimited Proposals'
                                            : `${plan.max_proposals} Proposal(s)`}
                                    </p>
                                </div>

                                {/* Profile Optimization */}
                                {plan.profile_optimization && (
                                    <div className="flex items-center gap-x-2">
                                        <img src="./check.svg" alt="" />
                                        <p className="font-light text-[#808080] text-center text-lg">
                                            Profile Optimization Included
                                        </p>
                                    </div>
                                )}
                            </div>
                            <button
                                onClick={() => handleSelectPlan(plan)}
                                className={`flex items-center justify-center gap-x-2 w-[12rem] font-semibold text-sm transition-colors duration-300 px-2 py-4 rounded-md mx-auto ${plan.name === 'Standard'
                                    ? 'bg-purple-500 text-white hover:bg-purple-600'
                                    : 'bg-gradient-to-r from-purple-500 to-pink-500 text-white hover:bg-gray-300'
                                    }`}                            >
                                SELECT {plan.name.toUpperCase()} PLAN
                            </button>
                        </div>
                    ))}
                </div>
                {/* Conditional rendering of the free trial text */}
                {!hasUserUsedTrial && (
                    <div className="mt-8 text-center">
                        <p className="font-light text-[#808080] text-lg">
                            Or get started with a <span className="font-bold">free trial</span>
                        </p>
                        <button
                            onClick={handleStartFreeTrial}
                            className=" mt-4 flex items-center justify-center gap-x-2 font-semibold text-sm custom-gradient transition-colors duration-300 px-2 py-4 rounded-md hover:text-[#800080] text-[#FFF] hover:bg-[#c0c5c5] mx-auto"
                        >
                            Start Free Trial
                        </button>
                    </div>
                )}
            </div>
        </div>
    );

}

export default Plans;
