// src/components/PaymentPage/PaymentPage.js

import React from 'react';
import PlanSummary from '../../../components/PaymentPage/PlanSummary';
import BillingForm from '../../../components/PaymentPage/BillingForm';
import { useNavigate } from 'react-router-dom';


const PaymentPage = () => {
    const navigate = useNavigate();
    return (
        <div
            id="payment"
            className="min-h-screen bg-[#F3F4FE] pb-16 pt-2 px-5 lg:px-24 flex flex-col items-center justify-center"

        >
            <div className="flex items-center justify-between w-full py-5">
                <img src="../logo.png" className="w-[13rem]" alt="Logo" />
                <button
                    onClick={() => navigate(-1)}
                    className="flex items-center gap-x-2 text-purple-600 hover:text-purple-800 font-semibold text-sm lg:text-base"
                >
                    <svg
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clipRule="evenodd"
                        ></path>
                    </svg>
                    Cancel
                </button>
            </div>
            <div className="container mx-auto pb-10">
                <div className="flex flex-col lg:flex-row bg-white shadow-lg rounded-lg overflow-hidden">
                    {/* Left Column: Billing Information */}
                    <div className="w-full lg:w-1/2 p-8">
                        <BillingForm />
                    </div>

                    {/* Right Column: Plan Summary */}
                    <div className="w-full lg:w-1/2 bg-[#F9FAFB] p-8">
                        <PlanSummary />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentPage;
