import React from 'react';

export const GradientButton = ({ type = 'button', value, onClick, isLoading, disabled, children }) => {
    return (
        <button
            type={type}
            value={value}
            onClick={onClick}
            disabled={isLoading || disabled}
            className={`w-full bg-gradient-to-br from-[#6E5DE9] via-[#A159F2] to-[#CD55F9] rounded-lg p-3 text-white font-semibold transition duration-200 hover:bg-[#757E7E] ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
            <span className="flex items-center justify-center gap-x-1">
                {isLoading ? 'Loading...' : children}
            </span>
        </button>
    );
};
