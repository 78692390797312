// src/components/SubscriptionPlans.js

import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSubscriptions, fetchCurrentSubscription, startFreeTrial } from '../../../store/slice/subscriptionSlice'; // Adjust the path as needed
import { useNavigate } from 'react-router-dom';
import { formatDateWithSuffix } from '../../../utils/dateUtils';
import PageLoader from '../../../components/preLoaders/PageLoader';

export const SubscriptionPlans = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Local state to manage toggle
    const [showAllPlans, setShowAllPlans] = useState(true);

    // Redux state
    const { currentSubscription, allSubscriptions, hasUserUsedTrial, status, error } = useSelector(
        (state) => state.subscription
    );

    const auth = useSelector((state) => state.auth);

    // Ref to track if data has been fetched
    const hasFetched = useRef(false);
    useEffect(() => {
        if (status === 'idle' || status === 'failed') {
            dispatch(fetchCurrentSubscription());
            dispatch(fetchSubscriptions());
            hasFetched.current = true; // Set the flag to true after dispatching
        }
    }, [dispatch, status]);

    const handleTogglePlans = () => {
        setShowAllPlans((prev) => !prev);
    };


    const handleSelectPlan = useCallback(
        (plan) => {
            navigate(`/payment?planId=${plan.id}`);
        },
        [navigate]
    );
    const handleStartFreeTrial = () => {
        dispatch(startFreeTrial())
            .unwrap()
            .then(() => {
                console.log('Free trial started successfully');
                navigate('/dashboard'); // Redirect to dashboard or another appropriate page
            })
            .catch((error) => {
                console.error('Error starting free trial:', error);
                alert(error.message || 'Failed to start free trial.');
            });
    };

    if (status === 'loading') {
        return <PageLoader />;
    }

    if (status === 'failed') {
        return <div>Error: {error?.message || 'Failed to load subscription details.'}</div>;
    }

    return (
        <div className="jobs-page bg-white h-full p-5">
            {/* Current Subscription Section */}
            {currentSubscription ? (
                <div className="bg-[#FCF4FE] rounded-[9px] p-4 mb-5 flex items-center justify-between">
                    {/* Current Plan Details */}
                    <div className="flex items-center">
                        {/* Logo */}
                        <div className="mr-4">
                            <div className="w-10 h-10">
                                <img className="h-10 w-30" src="../logo.png" alt="Logo" />
                            </div>
                        </div>
                        {/* Texts */}
                        <div>
                            <div className="flex items-center">
                                <p className="font-bold text-lg mr-2 text-textBlack">{currentSubscription.name} Plan</p>
                                <div className="bg-green-500 text-white px-1 py-1 rounded text-xs">Active</div>
                            </div>
                            <p className="text-sm text-grey2 mt-1">
                                {currentSubscription.name === 'Trial' ? (
                                    `Expires on ${formatDateWithSuffix(currentSubscription.end_date)}`
                                ) : (
                                    `Next payment: $${parseFloat(currentSubscription.price).toFixed(2)} on ${formatDateWithSuffix(currentSubscription.end_date)}`
                                )}
                            </p>
                        </div>
                    </div>

                    {/* "View all plans" Button */}
                    <button
                        onClick={handleTogglePlans}
                        className="bg-white py-2 px-4 rounded-md border border-transparent text-transparent bg-clip-text"
                        style={{
                            borderImage: 'linear-gradient(301.36deg, #CD55F9 37.81%, #A159F2 63.16%, #6E5DE9 89.91%) 1',
                            borderImageSlice: 1,
                            borderRadius: '0.375rem',
                            backgroundImage: 'linear-gradient(301.36deg, #CD55F9 37.81%, #A159F2 63.16%, #6E5DE9 89.91%)',
                            WebkitTextFillColor: 'transparent',
                            WebkitBackgroundClip: 'text',
                        }}
                    >
                        {showAllPlans ? 'Hide all plans' : 'View all plans'}
                    </button>
                </div>
            ) : (
                // If no current subscription, prompt to select a plan or start a trial
                <div className="bg-[#FCF4FE] rounded-[9px] p-4 mb-5 flex items-center justify-between">
                    {/* Logo */}
                    <div className="flex items-center">
                        <div className="mr-4">
                            <div className="w-10 h-10">
                                <img className="h-10 w-30" src="../logo.png" alt="Logo" />
                            </div>
                        </div>
                        {/* Prompt to select a plan */}
                        <div>
                            <p className="font-bold text-lg text-textBlack">No active subscription</p>
                            <p className="text-sm text-grey2 mt-1">Please select a plan to continue.</p>
                        </div>
                    </div>

                    {/* "View all plans" Button */}
                    <button
                        onClick={handleTogglePlans}
                        className="bg-white py-2 px-4 rounded-md border border-transparent text-transparent bg-clip-text"
                        style={{
                            borderImage: 'linear-gradient(301.36deg, #CD55F9 37.81%, #A159F2 63.16%, #6E5DE9 89.91%) 1',
                            borderImageSlice: 1,
                            borderRadius: '0.375rem',
                            backgroundImage: 'linear-gradient(301.36deg, #CD55F9 37.81%, #A159F2 63.16%, #6E5DE9 89.91%)',
                            WebkitTextFillColor: 'transparent',
                            WebkitBackgroundClip: 'text',
                        }}
                    >
                        {showAllPlans ? 'Hide all plans' : 'View all plans'}
                    </button>
                </div>
            )}

            {/* All Subscription Plans */}
            {showAllPlans && (
                <div className="grid grid-cols-1 gap-8 md:gap-3 lg:gap-8 md:grid-cols-3 lg:grid-cols-3 mt-4">
                    {/* Dynamically render subscription plans */}
                    {allSubscriptions.map((plan) => (
                        <div
                            key={plan.id}
                            className={`relative flex flex-col items-center justify-center bg-[#fff] pt-5 pb-10 rounded-md shadow ${currentSubscription && plan.id === currentSubscription.subscription_id
                                ? 'border-2 border-purple-500'
                                : ''
                                }`}
                        >
                            {/* Current Plan Badge */}
                            {currentSubscription && plan.id === currentSubscription.subscription_id && (
                                <div className="absolute top-0 right-0 mt-2 mr-2">
                                    <span className="bg-purple-500 text-white text-xs font-bold px-2 py-1 rounded">
                                        Current Plan
                                    </span>
                                </div>
                            )}

                            <div className="w-full text-center space-y-2 border-b px-8 py-4">
                                <h1 className="text-xs sm:text-base lg:text-xl font-semibold bg-gradient-to-br from-[#6E5DE9] via-[#A159F2] to-[#CD55F9] bg-clip-text text-transparent">
                                    {plan.name.toUpperCase()} PLAN
                                </h1>
                                <p className="text-xs sm:text-base lg:text-xl text-black font-bold flex justify-center items-baseline gap-x-1">
                                    <span className="text-5xl lg:text-6xl">${parseFloat(plan.price).toFixed(2)}</span>/month
                                </p>
                            </div>
                            <div className="space-y-2 my-7">
                                {/* Features */}
                                {plan.max_profiles !== undefined && (
                                    <div className="flex items-center gap-x-2">
                                        <img src="/check.svg" alt="Check" />
                                        <p className="font-light text-gray-500 text-center text-lg">
                                            {plan.max_profiles === null
                                                ? 'Unlimited Profiles'
                                                : `${plan.max_profiles} Profile${plan.max_profiles > 1 ? 's' : ''}`}
                                        </p>
                                    </div>
                                )}
                                {plan.max_proposals !== undefined && (
                                    <div className="flex items-center gap-x-2">
                                        <img src="/check.svg" alt="Check" />
                                        <p className="font-light text-gray-500 text-center text-lg">
                                            {plan.max_proposals === null
                                                ? 'Unlimited Proposals'
                                                : `${plan.max_proposals} Proposal${plan.max_proposals > 1 ? 's' : ''}`}
                                        </p>
                                    </div>
                                )}
                                {plan.profile_optimization && (
                                    <div className="flex items-center gap-x-2">
                                        <img src="/check.svg" alt="Check" />
                                        <p className="font-light text-gray-500 text-center text-lg">
                                            Profile Optimization Included
                                        </p>
                                    </div>
                                )}
                            </div>
                            {/* Button */}
                            <button
                                onClick={() => {
                                    if (currentSubscription && plan.id === currentSubscription.subscription_id) {
                                        // If the plan is already the current subscription, do nothing or show a message
                                        alert('This is your current subscription.');
                                    } else {
                                        // Handle plan selection, e.g., navigate to payment page
                                        handleSelectPlan(plan);
                                    }
                                }}
                                disabled={currentSubscription && plan.id === currentSubscription.subscription_id}
                                className={`flex items-center justify-center gap-x-2 w-[12rem] font-semibold text-sm transition-colors duration-300 px-2 py-4 rounded-md mx-auto ${plan.name === 'Standard'
                                    ? 'bg-purple-500 text-white hover:bg-purple-600 cursor-pointer'
                                    : 'bg-gradient-to-r from-purple-500 to-pink-500 text-white hover:bg-gray-300 cursor-pointer'
                                    } ${currentSubscription && plan.id === currentSubscription.subscription_id ? 'opacity-50 cursor-not-allowed' : ''}`}
                            >
                                {currentSubscription && plan.id === currentSubscription.subscription_id
                                    ? 'CURRENT PLAN'
                                    : `SELECT ${plan.name.toUpperCase()} PLAN`}
                            </button>
                        </div>
                    ))}

                    {/* Optionally, add a card for starting a free trial */}

                </div>
            )}
        </div>
    );
};
