import React, { useState } from 'react';

const termsData = [
  {
    title: '1. Acceptance of Terms',
    content: `By using GigShark, you agree to these Terms and Conditions. If you do not agree, please do not use the app.
              You must be at least 18 years old or have parental permission to use GigShark.`,
  },
  {
    title: '2. User Responsibilities',
    content: `Account Security: Users are responsible for maintaining the confidentiality of their account information and for all activities under their account.
              Compliance with Laws: Users must comply with all applicable laws and regulations, including those related to freelancing, payments, and employment.
              Prohibited Activities: Users may not use GigShark to engage in illegal or unauthorized activities, misuse the app by spamming, phishing, or fraud, or post harmful, misleading, or inappropriate content.`,
  },
  {
    title: '3. GigShark’s Responsibilities and Limitations',
    content: `Accuracy of Information: GigShark provides AI tools and freelance opportunity alerts, but we do not guarantee the accuracy or completeness of job postings or opportunities.
              Third-Party Services: GigShark integrates with third-party platforms like Upwork and Fiverr. We are not responsible for the terms, policies, or actions of third-party services.`,
  },
  {
    title: '4. Data Collection and Privacy',
    content: `Personal Information: GigShark collects personal information as outlined in our Privacy Policy to provide and improve our services.
              Usage Data: We may collect usage data to analyze user engagement and improve app performance.
              Third-Party Sharing: We do not share your personal information with third parties without consent unless required by law or for fraud prevention purposes.`,
  },
  {
    title: '5. Subscription & Payment Terms',
    content: `Subscription Fees: Some features of GigShark may require a subscription or payment. By subscribing, you agree to pay all applicable fees.
              Refund Policy: Refunds are available based on our refund policy, which complies with Google Play’s refund policies.
              Auto-Renewal: Subscriptions may auto-renew unless canceled. You can manage or cancel subscriptions through Google Play.`,
  },
  {
    title: '6. Intellectual Property',
    content: `Ownership: GigShark owns all rights to the app, including text, graphics, and code. Users may not copy or reproduce app content without permission.
              User-Generated Content: By uploading or submitting content, you grant GigShark a license to use, display, and distribute your content as necessary for app functionality.`,
  },
  {
    title: '7. Termination and Suspension',
    content: `GigShark reserves the right to suspend or terminate user accounts for violation of these Terms or if the app is used for unlawful purposes.`,
  },
  {
    title: '8. Disclaimers and Limitation of Liability',
    content: `No Guarantee: GigShark provides services on an “as is” basis, without guarantees or warranties of any kind.
              Limited Liability: GigShark is not liable for any indirect or incidental damages arising from the use of the app, including loss of data, missed opportunities, or unauthorized access to your account.`,
  },
  {
    title: '9. Changes to Terms and Conditions',
    content: `GigShark reserves the right to update these Terms at any time. Users will be notified of significant changes, and continued use of the app constitutes acceptance of updated Terms.`,
  },
  {
    title: '10. Governing Law',
    content: `These Terms are governed by the laws of the United States and User’s home state. All disputes will be resolved under this jurisdiction.`,
  },
];

function TermsAndConditions() {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleSection = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="max-w-4xl mx-auto p-8">
      <h1 className="text-3xl font-bold text-center mb-8">GigShark Terms and Conditions</h1>
      <div className="space-y-4">
        {termsData.map((term, index) => (
          <div
            key={index}
            className="border rounded-lg shadow-md"
          >
            <button
              onClick={() => toggleSection(index)}
              className="w-full text-left p-4 bg-gray-200 hover:bg-gray-300 rounded-t-lg flex justify-between items-center"
            >
              <span className="font-semibold">{term.title}</span>
              <span>{activeIndex === index ? '-' : '+'}</span>
            </button>
            {activeIndex === index && (
              <div className="p-4 text-gray-700 bg-white rounded-b-lg">
                <p>{term.content}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default TermsAndConditions;
