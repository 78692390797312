import { useNavigate } from "react-router-dom";
import DashboardBoardJobItem from "../../../components/DashboardBoardJobItem";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchJobs, setPagination } from "../../../store/slice/jobSlice";
import Pagination from "../../../components/Pagination";

export const JobsPage = () => {
  const dispatch = useDispatch();
  const { allJobs, status, error, totalPages } = useSelector((state) => state.job);
  // Add page state
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (status === 'idle' || status === 'failed') {
      dispatch(fetchJobs({ page: currentPage, limit: 50 }));
    }
  }, [dispatch, currentPage, status]);

  // Pagination handler
  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return;
    dispatch(setPagination({ page, totalPages }));
    setCurrentPage(page); // Sync with local state
  };

  if (status === 'loading') {
    return <div>Loading  Jobs...</div>;
  }

  if (status === 'failed') {
    return <div>Error: {error.message || 'Failed to load Jobs.'}</div>;
  }
  return (
    <div className="jobs-page pr-10">
      <div className="bg-white p-4 lg:p-8">
        <form
          //   onSubmit={handleSearchSubmit}
          className="flex lg:w-[24rem] lg:gap-x-4"
        >
          <div className="relative w-full">
            <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2 9C2 5.13401 5.13401 2 9 2C12.866 2 16 5.13401 16 9C16 10.8866 15.2537 12.5988 14.0402 13.8576C14.0064 13.8837 13.9738 13.9123 13.9428 13.9433C13.9118 13.9743 13.8833 14.0068 13.8572 14.0406C12.5984 15.2539 10.8863 16 9 16C5.13401 16 2 12.866 2 9ZM14.6174 16.0321C13.0778 17.2636 11.1249 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9C18 11.1251 17.2634 13.0782 16.0317 14.618L19.707 18.2933C20.0975 18.6838 20.0975 19.317 19.707 19.7075C19.3165 20.098 18.6833 20.098 18.2928 19.7075L14.6174 16.0321Z"
                  fill="#C2C2C2"
                />
              </svg>
            </div>
            <input
              type="text"
              id="simple-search"
              className="bg-white text-[#808080] placeholder:font-light text-sm rounded-2xl  focus:outline-none block w-full pl-10 p-3 hover-gradient"
              placeholder="Search for jobs"
              //   value={searchQuery}
              //   onChange={(e) => setSearchQuery(e.target.value)}
              required
            />
          </div>
          <button
            type="submit"
            className="py-2.5 px-7  text-base  font-light bg-[#FCF4FE] hover-gradient focus:outline-none "
          >
            <span className="bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text text-transparent">
              Search
            </span>
          </button>
        </form>

        {allJobs.length < 1 &&
          <div className="p-6 mt-5 pb-10">
            <div className="mx-auto text-center ">
              <img src="../jobEmpty.svg" alt="" className="mx-auto w-[16rem]" />
              <h2 className="text-base md:text-lg font-bold text-[#1A1A1A]  ">
                No Job Listing Yet!
              </h2>
              <p className="text-[12px] sm:text-[12px]  sm:pt-0.5  font-light text-[#808080]  ">
                Available jobs will appear here
              </p>
            </div>
          </div>
        }



        <div>

          {Array.isArray(allJobs) &&
            <>
              <div className="flex items-center gap-x-1 pb-2 pt-5">
                <h1 className="text-lg font-semibold">Search Results</h1>
                <p className="font-light text-sm text-[#808080]">({allJobs.length} Jobs found)</p>
              </div>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                {
                  allJobs.map((e) => <DashboardBoardJobItem jobDetails={e} />)
                }
              </div>

              {/* Pagination */}
              <Pagination currentPage={currentPage} totalPages={totalPages} handlePageChange={(e) => handlePageChange(e)} />
            </>
          }
          {/* Search Results +++++++++++++++++++++++++ */}
        </div>
      </div>
    </div>
  );
};
