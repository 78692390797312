// src/components/PaymentPage/PlanSummary.js

import React from 'react';
import SummaryItem from './SummaryItem';


const PlanSummary = () => {
    // Example plan data; replace with actual data from props or Redux store
    const selectedPlan = {
        name: 'Premium',
        price: 29.99,
        features: [
            'Unlimited Profiles',
            'Unlimited Proposals',
            'Profile Optimization',
        ],
    };

    const taxes = 2.40; // Example tax amount
    const total = selectedPlan.price + taxes;

    return (
        <div>
            <h2 className="text-2xl font-semibold mb-6">Your Subscription</h2>
            <div className="space-y-4">
                <div className="flex items-center justify-between">
                    <div className="flex items-center gap-x-2">
                        {/* <CheckIcon className="w-4 h-4 text-green-500" aria-hidden="true" /> */}
                        <p className="font-medium text-gray-700">{selectedPlan.name} Plan</p>
                    </div>
                    <p className="font-semibold text-gray-800">${selectedPlan.price.toFixed(2)}/month</p>
                </div>
                <div className="space-y-2">
                    {selectedPlan.features.map((feature, index) => (
                        <div key={index} className="flex items-center gap-x-2">
                            {/* <CheckIcon className="w-4 h-4 text-green-500" aria-hidden="true" /> */}
                            <p className="text-gray-600">{feature}</p>
                        </div>
                    ))}
                </div>
            </div>

            <div className="mt-8 border-t pt-4">
                <SummaryItem label="Subtotal" amount={`$${selectedPlan.price.toFixed(2)}`} />
                <SummaryItem label="Taxes" amount={`$${taxes.toFixed(2)}`} />
                <SummaryItem label="Total" amount={`$${total.toFixed(2)}`} className="font-bold text-xl" />
            </div>
        </div>
    );
};

export default PlanSummary;
